import React, { useState, useEffect, useRef, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import apiServices from '../../services/api'
import { SocketContext } from '../../context/socket';
import { toast } from 'react-toastify';
import Storages from '../../constants/storages';
import Slider from '@material-ui/core/Slider';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
} from "@material-ui/core";
import DraggableModal from '../Modal/DraggableModal';
import { Stack } from '@mui/material';

const useStyles = makeStyles(({ palette, mode }) => ({
  color: {
    color: palette.primary[mode]
  },
  modalFormControl: {
    '& label': {
      color: palette.color[mode],
    },
    '& label.Mui-disabled': {
      color: palette.lightGray[mode],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: palette.lightGray[mode],
      },
      '&:hover fieldset': {
        borderColor: palette.primary[mode],
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.primary[mode],
      },
      '&.Mui-disabled fieldset': {
        borderColor: palette.lightGray[mode],
      },
      '&.Mui-disabled .MuiSelect-selectMenu': {
        color: palette.lightGray[mode],
      },
    },
  },
  modalFormSelect: {
    backgroundColor: palette.background[mode] + " !important",
    color: palette.color[mode] + " !important",
  },
  testSpeakerBtn: {
    color: palette.primary[mode],
    textTransform: "none",
    borderRadius: "20px",
    paddingBottom: "2px",
    borderColor: palette.lightGray[mode],
  }
}));

export const UserDeviceManagement = ({
  open,
  location,
  handleClose,
}) => {
  const locationRef = useRef(location)
  const [selectedMic, setSelectedMic] = useState({})
  const [microphones, setMicrophones] = useState([])
  const [speakers, setSpeakers] = useState([])
  const [selectedSpeaker, setSelectedSpeaker] = useState({})
  const [selectedCamera, setSelectedCamera] = useState({})
  const [cameras, setCameras] = useState([])
  const [volume, setVolume] = useState(100)
  const [loading, setLoading] = useState(false)
  const debounceTimeoutRef = useRef(null);
  const socket = useContext(SocketContext)
  const classes = useStyles()

  useEffect(() => {
    if (socket) {
      socket.on('msg', ({ event, msg, from }) => {
        if (!from || !locationRef.current || from.toLowerCase() !== locationRef.current.toLowerCase()) return;
        if (event === 'list-devices') {
          let { cameras, microphones, speakers, volume } = msg
          let audiooutput = speakers.find((s) => s.selected === true)
          let audioinput = microphones.find((s) => s.selected === true)
          let camera = cameras.find((s) => s.selected === true)
          if (volume) setVolume(volume)
          setSelectedMic(audioinput)
          setSelectedSpeaker(audiooutput)
          setSelectedCamera(camera)
          setCameras(cameras)
          setMicrophones(microphones)
          setSpeakers(speakers)
          setLoading(false)
        }
      })
    }
    return (() => {
      if (socket) {
        socket.off('msg')
      }
    })
  }, [])

  useEffect(() => {
    locationRef.current = location
    if (open) {
      setLoading(true)
      if (!location) {
        setLoading(false)
        handleClose()
        return
      }
      sendRequestListDevices(location)
    }
  }, [open, location])

  const sendRequestListDevices = async (to) => {
    try {
      await apiServices.sendMessage({
        to: to,
        event: "list-devices"
      })
    } catch (error) {
      console.error(1330, error)
    }
  }

  const handleOnClickMicro = async (event, item) => {
    try {
      event.preventDefault()
      var res = await apiServices.sendMessage({
        event: "update-mic",
        to: location,
        msg: item
      })
      if (res.data.code === 0) {
        setSelectedMic(item)
      } else if (res.data.code === 1) {
        // Reception is not manager
        // show toast and avoid to access  Location device management
        toast.warning(res.data.msg, 5000)
        localStorage.removeItem(Storages.IS_RECEPTION_MANAGER)
        handleClose()
      } else {
        toast.error("Failed to set location's camera. Error: " + res.data.msg, { autoClose: 5000 })
      }
    } catch (error) {
      console.error(1332, error)
      toast.error("Failed to set location's microphone. Error: " + error.message, { autoClose: 5000 })
    }
  }

  const handleOnClickSpeaker = async (event, item) => {
    try {
      event.preventDefault()
      var res = await apiServices.sendMessage({
        event: "update-speaker",
        to: location,
        msg: item
      })
      if (res.data.code === 0) {
        setSelectedSpeaker(item)
      }
      else if (res.data.code === 1) {
        // Reception is not manager
        // show toast and avoid to access  Location device management
        toast.warning(res.data.msg, 5000)
        localStorage.removeItem(Storages.IS_RECEPTION_MANAGER)
        handleClose()
      } else {
        toast.error("Failed to set location's speaker. Error: " + res.data.msg, { autoClose: 5000 })
      }
    } catch (error) {
      console.error(1333, error)
      toast.error("Failed to set location's speaker. Error: " + error.message, { autoClose: 5000 })
    }
  }

  const handleOnClickCamera = async (event, item) => {
    try {
      event.preventDefault()
      var res = await apiServices.sendMessage({
        event: "update-camera",
        to: location,
        msg: item
      })
      if (res.data.code === 0) {
        setSelectedCamera(item)
      }
      else if (res.data.code === 1) {
        // Reception is not manager
        // show toast and avoid to access  Location device management
        toast.warning(res.data.msg, 5000)
        localStorage.removeItem(Storages.IS_RECEPTION_MANAGER)
        handleClose()
      } else {
        toast.error("Failed to set location's camera. Error: " + res.data.msg, { autoClose: 5000 })
      }
    } catch (error) {
      console.error(1334, error)
      toast.error("Failed to set location's camera. Error: " + error.message, { autoClose: 5000 })
    }
  }

  const handleChangeVolume = async (e, vol) => {
    setVolume(vol)
    clearTimeout(debounceTimeoutRef.current)
    debounceTimeoutRef.current = setTimeout(async () => {
      var res = await apiServices.sendMessage({
        event: "set-volume",
        to: locationRef.current,
        msg: vol
      })
      if (res.data.code !== 0) {
        toast.error("Failed to set location's volume. Error: " + res.data.msg, { autoClose: 5000 })
      }
    }, 500);
  }

  const testLocationSpeaker = async () => {
    try {
      var res = await apiServices.sendMessage({
        event: "play-test-sound",
        to: location,
      });
      if (res.data.code === 0) {
        toast.success("Sent!")
      } else {
        toast.error("Error while testing speaker: " + res.data.msg)
      }
    } catch (err) {
      console.error(err)
      toast.error("Error while testing speaker: " + err.message)
    }
  };

  return (
    <DraggableModal
      closeButton={true}
      onCloseButton={handleClose}
      header={<h5 className="mb-0">{location} Device Management</h5>}
      body={<>
        {loading ?
          <span>Loading...</span> :
          <>
            <FormControl fullWidth variant="outlined" className={classes.modalFormControl}>
              <InputLabel id="mic-label" >Microphone</InputLabel>
              <Select
                labelId="mic-label"
                labelWidth={80}
                value={selectedMic && selectedMic.deviceId || ""}
                className={classes.modalFormSelect}
              >
                {
                  Array.isArray(microphones) && !microphones[0] ? <MenuItem value="">No microphone found.</MenuItem> :
                    microphones.map((item, index) =>
                      <MenuItem key={index} value={item.deviceId} onClick={(event) => handleOnClickMicro(event, item)}>
                        {item.label}
                      </MenuItem>
                    )
                }
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" className={'mt-3 ' + classes.modalFormControl}>
              <InputLabel id="speaker-label" >Speaker</InputLabel>
              <Select
                labelId="speaker-label"
                labelWidth={60}
                value={selectedSpeaker && selectedSpeaker.deviceId || ""}
                className={classes.modalFormSelect}
              >
                {
                  Array.isArray(speakers) && !speakers[0] ? <MenuItem value="">No Speaker found.</MenuItem> :
                    speakers.map((item, index) =>
                      <MenuItem key={index} value={item.deviceId} onClick={(event) => handleOnClickSpeaker(event, item)}>
                        {item.label}
                      </MenuItem>
                    )
                }
              </Select>
            </FormControl>
            <FormControl fullWidth variant="outlined" className={'mt-3 ' + classes.modalFormControl}>
              <InputLabel id="camera-label" >Camera</InputLabel>
              <Select
                labelId="camera-label"
                labelWidth={60}
                value={selectedCamera && selectedCamera.deviceId || ""}
                className={classes.modalFormSelect}
              >
                {
                  Array.isArray(cameras) && !cameras[0] ? <MenuItem value="">No Camera found.</MenuItem> :
                    cameras.map((item, index) =>
                      <MenuItem key={index} value={item.deviceId} onClick={(event) => handleOnClickCamera(event, item)}>
                        {item.label}
                      </MenuItem>
                    )
                }
              </Select>
            </FormControl>
            <div className={'mt-2 ' + classes.modalFormControl}>
              <div className='d-flex justify-content-between'>
                <InputLabel className='mb-0 d-flex align-items-center'>Volume</InputLabel>
                <Button onClick={testLocationSpeaker} className={classes.testSpeakerBtn} variant='outlined'>
                  <b>Play test sound</b>
                </Button>
              </div>
              <Stack spacing={2} direction="row" alignItems="center">
                <VolumeDown onClick={(event) => handleChangeVolume(event, volume - 10)} style={{ cursor: "pointer" }} />
                <Slider className={classes.color} value={volume} onChange={handleChangeVolume} valueLabelDisplay="auto" />
                <VolumeUp onClick={(event) => handleChangeVolume(event, volume + 10)} style={{ cursor: "pointer" }} />
              </Stack>
            </div>
          </>
        }
      </>}
      show={open}
    />
  )
}