import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { connect } from "react-redux";
const useStyle = makeStyles(({palette,mode}) => ({
  version:{
    position:"absolute",
    bottom:0,
    left:0,
    marginLeft:"5px",
    zIndex:"1",
    color:palette.color[mode]
  }
}))
const VersionComponent = (props) => {
  const classes = useStyle();
  const [appVersion, setAppVersion] = useState("");

  useEffect(() => {
    // not sure if it is called everytime. but sure it is called the first time and it is enough
    setAppVersion(localStorage.lucas_therapies_version)
  }, [localStorage.lucas_therapies_version]) 

  return (
    <div className={classes.version}>
      <small>
      {appVersion || localStorage.lucas_therapies_version} ({process.env.REACT_APP_COMMIT_HASH} {props.commitHash})
      </small>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    commitHash:state.apiCommitHash
  }
}

export default connect(mapStateToProps, {})(VersionComponent);