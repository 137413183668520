import React, { useEffect, useState, useRef,useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import './style.css';
import { useSelector } from 'react-redux';
import ListLocations from './ListLocations';
import Str from '../../constants/string';
import Constant from '../../constants/constant';
import { ButtonBase ,Grid} from '@material-ui/core';
import KeyboardArrowRightOutlinedIcon from '@material-ui/icons/KeyboardArrowRightOutlined';
import KeyboardArrowLeftOutlinedIcon from '@material-ui/icons/KeyboardArrowLeftOutlined';
import VersionComponent from '../../Version'
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined';
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';
import AlarmAudio from "../../assets/sounds/alarm.mp3";
import * as $ from 'jquery';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import PhonePausedOutlinedIcon from '@material-ui/icons/PhonePausedOutlined';

const useStyle = makeStyles(({palette,mode}) => ({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    position:"relative",
    justifyContent:"center",
    backgroundColor:palette.background[mode],
    overflowX:'hidden'
  },
  locations:{
    width:"250px",
    height:"100vh",
    position:"absolute",
    top:0,
    backgroundColor: 'rgba(200,200,200,0.5)',
    overflowY:'auto',
    boxShadow:"0 0 25px #8c8c8c45;",
    transition: 'all 0.5s ease-out',
    '&:hover':{
      backgroundColor: '#eeeeee',
    },
    '&:hover $toggleBtn':{
      display:"block"
    }
  },
  toggleBtn:{
    color:"black",
    position:"fixed",
    display:"none",
    zIndex:"2",
    borderRadius:"5px 0px 0px 5px",
    marginTop:"48vh",
    backgroundColor:"#eeeeee",
    // transition: 'all 0.9s ease-out',
    marginLeft:"-35px",
    '&:focus':{
      outline:"none"
    }
  },
  list:{
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    flexDirection: 'column'
  },
  icon:{
    borderRadius:'5px',
    backgroundColor:"white",
    fontSize:"7rem",
    opacity:"0.5",
    margin:"5px",
    border:"1px solid"
  },
  holdIcon: {
    width: "14.9rem",
    borderRadius:'5px',
    backgroundColor: "rgba(255, 0, 0, 0.7)",
    color: "rgb(235, 235, 235)",
    margin: "5px",
    paddingRight: "7px",
    border:"1px solid",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    fontSize: "3rem",
  },
  holdIconTimer: {
    display: "flex",
    flexDirection: 'column',
    lineHeight: "1",
    marginLeft: "5px",
  }
}))

const LocationArea = React.memo((props) => {
  const {
    callState,
    remoteLocations,
    allLocationData,
    localUserData,
    handleItemSleep,
    locationEngaged,
    toggleLeftSideBar,
    micro,
    camera,
    monitorCallStatus
  } = props;
  const classes = useStyle();
  const [locationsBar, setLocationsBar] = useState(true);
  const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
  const [sizeLocationItem, setSizethLocationItem] = useState(["230px", "130px"]); // width, height
  const [isVideoPaused, setIsVideoPaused] = useState(false);
  const [isCallOnHold, setIsCallOnHold] = useState(false);
  const [callHoldTimer, setCallHoldTimer] = useState(0);
  const refLocationArea = useRef();
  const callAlarmInterval = useRef(null);
  const ListLocationsElement = useMemo(
    () => ListLocations,
    [
      callState,
      sizeLocationItem[0],
      sizeLocationItem[1],
      remoteLocations,
      allLocationData,
      localUserData,
      locationEngaged,
      showSecondaryLocations, 
    ]
  );

  useEffect(() => {
    if($("#locationVideo")[0]) {      
      $("#locationVideo")[0].addEventListener("pause", videoPaused)
      $("#locationVideo")[0].addEventListener("play", videoPlayed)
    }

    return () => {
      if($("#locationVideo")[0]) {
        $("#locationVideo")[0].removeEventListener("pause", videoPaused)
        $("#locationVideo")[0].removeEventListener("play", videoPlayed)
      }

      clearInterval(callAlarmInterval.current)
      callAlarmInterval.current = null;
      if(document.getElementById("alarmAudio")) {
        document.getElementById("alarmAudio").pause();
      }
    }
  }, [])

  const videoPaused = () => {
    setIsVideoPaused(true)
    if(callState) {
      console.alert(5901, "Video paused during a call with", locationEngaged)
    }
  }

  const videoPlayed = () => {
    setIsVideoPaused(false)
  }

  useEffect(() => {
    if(callState) {
      clearInterval(callAlarmInterval.current)
      callAlarmInterval.current = null;
      document.getElementById("alarmAudio").pause();
      return;
    }

    let ringCallAlarm = false;
    for(const remoteLocation of remoteLocations) {
      if(remoteLocation.status === Constant.INCOMING && !remoteLocation.inCall && remoteLocation.calltag !== Str.KIOSK) {
        ringCallAlarm = true;
        break;
      }
    }

    if(ringCallAlarm) {
      if(!callAlarmInterval.current) {
        document.getElementById("alarmAudio").play();
        callAlarmInterval.current = setInterval(() => {
          document.getElementById("alarmAudio").play();
        }, 4000)
      }
    } else {
      clearInterval(callAlarmInterval.current)
      callAlarmInterval.current = null;
      document.getElementById("alarmAudio").pause();
    }
  }, [remoteLocations, callState])

  useEffect(() => {
    if(remoteLocations[0] && locationEngaged) {
      const found = remoteLocations.find(item => item.username === locationEngaged && item.status === Constant.HOLD)
      if(found) {
        setIsCallOnHold(true)
        setCallHoldTimer(found.holdTimer)
      } else {
        setIsCallOnHold(false)
        setCallHoldTimer(0)
      }
    }
  }, [remoteLocations, locationEngaged])

  // calculate width and height of location item. resize screen
  useEffect(() => {    
    window.addEventListener('resize', setDimension);
    return(() => {
        window.removeEventListener('resize', setDimension);
    })
  }, [sizeLocationItem,refLocationArea])

  // calculate width and height of location item.
  useEffect(()=>{
    setDimension()
  },[allLocationData,locationEngaged,showSecondaryLocations,monitorCallStatus])

  // calculate width and height of location item after completely open/close sidebar.
  useEffect(()=>{
    const timeout=setTimeout(()=>setDimension(),600)
    return()=>{
      clearTimeout(timeout)
    }
  },[toggleLeftSideBar])

  useEffect(() => {
    if (locationEngaged) {
      setLocationsBar(false)
    } 
  }, [locationEngaged])

  useEffect(() => {
    if (monitorCallStatus) {
      setLocationsBar(false)
    } 
  }, [monitorCallStatus])

  const toggleLocationsBar=()=>{
    setLocationsBar((prevState)=>!prevState)
  }
  const setDimension=()=>{
    var length=allLocationData?.length
    if(!showSecondaryLocations) {
      length = allLocationData.filter(item => item.isPrimary)?.length || 0
    }
    if(length==0) return
    const windowSize=      [
      refLocationArea?.current?.clientWidth,
      refLocationArea?.current?.clientHeight,
    ]
    let sqrt=Math.sqrt(length)
    let round=Math.round(sqrt)
    var width=windowSize[0]-75
    var height=windowSize[1]-75
    if(round<sqrt) round++;
    if(length>3){
      width=width/round
      if(length> round*(round-1))
        height=height/round
      else height=height/(round-1)
    }
    else {
      width=width/length
    }
    setSizethLocationItem([
      width,height
    ])
  }

  function secondsToHms(seconds) {
    // Create a Date object with the seconds converted to milliseconds
    const date = new Date(seconds * 1000);
    
    // Adjust the date to be at the start of the day (to avoid timezone issues)
    date.setUTCHours(date.getUTCHours());

    // Use Intl.DateTimeFormat to format the time
    const formatter = new Intl.DateTimeFormat('en-GB', {
        minute: '2-digit',
        second: '2-digit',
        timeZone: 'UTC'
    });

    return formatter.format(date);
  }

  return (
    <div className={classes.root}>
      <div className={monitorCallStatus ? 'col-md-12' : 'd-none'}>
        <div class="row h-100">
          <div class="col-md-6 p-1 d-flex">
            <video autoplay="1" id="monitorLocationVideo" playsinline style={{width: '100%'}}></video>
            <audio autoplay="1" id="monitorLocationAudio"></audio>
          </div>
          <div class="col-md-6 p-1 d-flex">
            <video autoplay="1" id="monitorReceptionVideo" playsinline style={{width: '100%'}}></video>
            <audio autoplay="1" id="monitorReceptionAudio"></audio>
          </div>
        </div>
      </div> 
      <video className='location_video' autoPlay='1' id='locationVideo' playsInline 
        style={{display:(locationEngaged)?'flex':"none"}} />
      {locationEngaged && isVideoPaused && 
        <span className='rounded-circle p-2' 
          style={{ position: "absolute", top: "49%", left: "49%", backgroundColor: "#707070"}} 
          title='Your connection is poor. Video is paused to save bandwidth for audio'>
          <VideocamOffIcon fontSize='large' color='warning'/>
        </span>
      }
      <audio autoPlay='1' id='locationAudio' />
      <audio id='alarmAudio' src={AlarmAudio} />
      <div className='my-auto py-auto text-center' style={{position:"absolute"}}>
        {callState && locationEngaged && !micro && <MicOffOutlinedIcon className={classes.icon} />}
        {callState && locationEngaged && !camera && <VideocamOffOutlinedIcon className={classes.icon} />}
        {locationEngaged && isCallOnHold && 
          <div className={classes.holdIcon} title='This call is on hold'>
            <PhonePausedOutlinedIcon style={{fontSize:"3rem"}}/>
            <div className={classes.holdIconTimer}>
              {secondsToHms(callHoldTimer)}
            </div>
          </div>
        }
      </div>
      {(locationEngaged || monitorCallStatus)?
        <div className={classes.locations} style={{ right: (locationsBar)? '0' : '-250px', zIndex: 2 }} >
          <ButtonBase className={classes.toggleBtn} style={{display:(locationsBar)?"":"block"}} 
            onClick={toggleLocationsBar}>
              {
                locationsBar?
                <KeyboardArrowRightOutlinedIcon fontSize='large'/>
                :<KeyboardArrowLeftOutlinedIcon fontSize='large'/>
              }
          </ButtonBase>
          <div className={classes.list}>
            <ListLocationsElement
              callState={callState}
              handleClickLocationItem={(index) => props.handleClickLocationItem(index)}
              startMonitorCall={(index) => props.startMonitorCall(index)}
              handleItemCall={() => props.handleItemCall()}
              handleClickHoldLocation={props.handleClickHoldLocation}
              remoteLocations={remoteLocations}
              allLocationData={allLocationData}
              localUserData={localUserData}
              handleItemSleep={handleItemSleep}
              width='230px'
              height="130px"
            />
          </div>
        </div>
        :
        <div style={{width:"95%",height:"80%"}} ref={refLocationArea}>
          <Grid container spacing={1} direction='row' justifyContent="center" alignItems="center">
            <ListLocationsElement
                callState={callState}
                handleClickLocationItem={(index) => props.handleClickLocationItem(index)}
                startMonitorCall={(index) => props.startMonitorCall(index)}
                handleItemCall={() => props.handleItemCall()}
                handleClickHoldLocation={props.handleClickHoldLocation}
                remoteLocations={remoteLocations}
                allLocationData={allLocationData}
                localUserData={localUserData}
                handleItemSleep={handleItemSleep}
                width={sizeLocationItem[0]}
                height={sizeLocationItem[1]}
              />
          </Grid>
        </div>
      }
        <VersionComponent/>
    </div>
  )
});

export default LocationArea;
