
import React, { useEffect , useState, memo, useContext} from 'react';
import './modal-style.css';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { yellow, red, green } from '@material-ui/core/colors';
import { SocketContext } from '../../context/socket'
import ScannerIcon from '@material-ui/icons/Scanner';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import apiService from "../../services/api"
import { toast } from "react-toastify"
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import DraggableModal from './DraggableModal';

const YellowButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(yellow[700]),
    backgroundColor: yellow[500],
    '&:hover': {
      backgroundColor: '#cccc00',
    },
  },
}))(Button);

const GreenButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[700]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: '#008800',
    },
  },
}))(Button);

const RedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[700]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: '#cc0000',
    },
  },
}))(Button);
const useStyle = makeStyles(({palette,mode}) => ({
  modalFormControl: {
    '& label': {
      color: palette.color[mode],
    },
    '& label.Mui-disabled': {
      color: palette.lightGray[mode],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: palette.lightGray[mode],
      },
      '&:hover fieldset': {
        borderColor: palette.primary[mode],
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.primary[mode],
      },
      '&.Mui-disabled fieldset': {
        borderColor: palette.lightGray[mode],
      },
      '&.Mui-disabled .MuiSelect-selectMenu': {
        color: palette.lightGray[mode],
      },
    },
  },
  modalFormSelect: {
    backgroundColor:palette.background[mode] + " !important",
    color:palette.color[mode] + " !important",
  },
}))
const ScanRequestModal = memo((props)=>{
    const {allLocations=[], locationEngaged, open, handleClose, reception}=props
    const [showModal, setShowModal] = useState(false)
    const [location, setLocation] = useState("");
    const [paperSource, setPaperSource] = useState("glass");
    const [disableInputs, setDisableInputs] = useState(false);
    const [scanLog, setScanLog] = useState("");
    const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
    const socket = useContext(SocketContext)
    const classes=useStyle()
    const [disableScan,setDisableScan]=useState(false)
    const [batchID,setBatchID]=useState('')

    useEffect(() => {
      socket.on('msg',({event, msg})=>{
        if(event === "scan-res") {
          if(msg.code !== 0) {
            setDisableInputs(false);
            setDisableScan(false);
          } 
          setScanLog(msg.msg)
        }else if(event ==='scan-result-is-ready'){
          setDisableScan(false)
          setBatchID(msg.batchID)
          setScanLog("Last scan completed, Would you like to continue scanning?")
        }
      })

      return () => {
        socket.off("msg")
      }
    }, [])

    useEffect(() => {
      if(!open) {
        setShowModal(false)
      } else {
        setDisableInputs(false);
        setDisableScan(false);
        if(paperSource === "glass") {
          setScanLog(`Please ask the patient to put their document face down into the scanner. 
            Once done, you can proceed by pressing the scan button.`)
        } else if (paperSource === "duplex") {
          setScanLog(`Please ask the patient to insert their document into the scanner feeder. 
            Once done, you can proceed by pressing the scan button.`)
        }
        setBatchID('')
        setShowModal(true)
      }
    }, [open])

    useEffect(() => {
      setLocation(locationEngaged || "")
      setBatchID('')
    },[locationEngaged])

    useEffect(() => {
      if(paperSource === "glass") {
        setScanLog(`Please ask the patient to put their document face down into the scanner. 
          Once done, you can proceed by pressing the scan button.`)
      } else if (paperSource === "duplex") {
        setScanLog(`Please ask the patient to insert their document into the scanner feeder. 
          Once done, you can proceed by pressing the scan button.`)
      }
    }, [paperSource])

    function onLocationChanged(event) {
      setLocation(event.target.value);
    }

    function onPaperSourceChanged(event) {
      setPaperSource(event.target.value);
    }

    async function submit() {
      try{
        if(!location) {
          toast.error(`'location' is required.`, {autoClose: 5000})
          return;
        }

        setDisableInputs(true);
        setDisableScan(true);
        
        const scanRes = await apiService.sendScanRequest({
          location, 
          paperSource, 
          batchID, 
          status: "start"
        })
        if(scanRes.data.code !== 0) {
          setScanLog("Error while sending scan request. message: " + scanRes.data.msg)
        } else {
          setScanLog("Scanning...")
        }
      } catch (err) {
        console.error(err)
        setScanLog("Error while sending scan request. message: " + err.message)
      }
    }

    async function cancel(close) {
      try{
        await apiService.sendScanRequest({
          location, 
          status: "cancel"
        })
        if(close) {
          handleClose(true)
        } else {
          toast.success("Scanner has been reset.")
        }
      } catch (err) {
        console.error(err)
        setScanLog("Error while sending cancel request. message: " + err.message)
      }
    }

    return (
      <DraggableModal
        closeButton={false}
        header={<h5 className="mb-0">Scan Request</h5>}
        body={<>
          <FormControl fullWidth variant="outlined" className={classes.modalFormControl}>
            <InputLabel id="location-label" disabled={disableInputs}>To Location</InputLabel>
            <Select
              labelId="location-label"
              onChange={onLocationChanged}
              labelWidth={80}
              value={location}
              disabled={disableInputs}
              className={classes.modalFormSelect}
            >
              <MenuItem value="">Please choose ...</MenuItem>
              {allLocations.map((item, index) => {
                const isSec = !item.isPrimary;
                if(isSec && !showSecondaryLocations) return;
                return (
                  <MenuItem key={index} value={item.username}>
                    {item.locationname}
                    {isSec && 
                      <sup style={{color: "#f3c200", fontWeight: "900"}} title='Secondary location'>&nbsp;2nd</sup>
                    }
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="outlined"  className={'mt-3 ' + classes.modalFormControl}>
            <InputLabel id="scan-type" disabled={disableInputs}>Paper Source</InputLabel>
            <Select
              labelId="scan-type"
              onChange={onPaperSourceChanged}
              labelWidth={80}
              value={paperSource}
              disabled={disableInputs}
              className={classes.modalFormSelect}
            >
              <MenuItem key="1" value="glass">Glass</MenuItem>
              <MenuItem key="2" value="duplex">Duplex</MenuItem>
            </Select>
          </FormControl>
          <div style={{marginTop: "10px", marginBottom: "5px"}}>{scanLog}</div>
        </>}
        footer={<>
          {!disableInputs &&
            <YellowButton className="dialog_btn" variant="contained" color="secondary" 
              startIcon={<SettingsBackupRestoreIcon />} onClick={() => cancel()}>
              Reset scanner
            </YellowButton> 
          }
          <RedButton className="dialog_btn" variant="contained" color="secondary" 
            startIcon={<CloseIcon />} onClick={() => cancel(true)}>
            Cancel
          </RedButton>
          <GreenButton className="dialog_btn" variant="contained" color="secondary" 
            startIcon={<ScannerIcon />} onClick={submit} disabled={disableScan}>
            Scan
          </GreenButton>
        </>}
        show={showModal}
      />
    )
})
export default ScanRequestModal