import React from "react";
import { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Slider from "@material-ui/core/Slider";
import VolumeUp from "@material-ui/icons/VolumeUp";
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import apiService from "../../services/api";

const useStyles = makeStyles({
    root: {
        width: '80%',
        marginRight: '1.5em'
    }
});

const muiTheme = createTheme({
    overrides: {
        MuiSlider: {
            thumb: {
                color: "white",
            },
            track: {
                color: 'white'
            },
            rail: {
                color: 'black'
            }
        }
    }
});

export default function AudioVolume(props) {
    const classes = useStyles();

    const handleChange = (event, newValue) => {
        props.setVolumeValue(newValue);
        apiService.sendMessage({
            to: props.receptionName.current,
            event: "sendVolumeToReception",
            msg: {volume: newValue}
        }).catch((err) => {
          console.error(err);
        })
    };

    useEffect( () => {
        props.setVolumeValue(localStorage.getItem('location_volume') || 75);
    }, [])

    useEffect(() => {
        localStorage.setItem('location_volume', props.volumeValue);
    }, [props.volumeValue]);

    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item>
                    <VolumeUp style={{ color: 'white' }} />
                </Grid>
                <Grid item xs>
                    <ThemeProvider theme={muiTheme}>
                        <Slider
                            value={props.volumeValue}
                            onChange={handleChange}
                            aria-labelledby="continuous-slider"
                        />
                    </ThemeProvider>
                </Grid>
            </Grid>
        </div>
    );
}
