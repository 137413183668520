import React, { Component } from 'react';
import * as $ from 'jquery';
import './remotelocationvideoview.css';
import Constant from '../../constants/constant';
import { Button } from '@material-ui/core';
import Str from '../../constants/string';
import UserColor from '../../constants/usercolor';
import CallIcon from '../../assets/images/hold_on_icon.png'
import AlarmAudio from "../../assets/sounds/alarm.mp3";
import { ButtonBase, Tooltip } from '@material-ui/core'
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import { connect } from 'react-redux';
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';

class RemoteLocationVideoView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            track: this.props.track,
            othertrack: this.props.othertrack,
            video_tag_id: this.props.video_tag_id,
            locationName: this.props.locationName,
            index: this.props.index,
            calltag: this.props.calltag,
            calltagColor: this.props.calltagColor,
            status: this.props.status,
            localUserData: this.props.localUserData,
            holdtime: Constant.ZERO,
            width:this.props.width ||'100%',
            height:this.props.height || '100%',
            id:this.props.id,
            tracksMutedContent:[],
            connectionQualityColor: "white",
            connectionQualityText: "Loading...",
            connectionQuality: this.props.connectionQuality || 0
        };
        this.countTimer = null;
        this.count = this.count.bind(this);

        this.alarmTimer = null;
        this.alarm = this.alarm.bind(this);

        this.audio = null;

        this.handleClickItem = this.handleClickItem.bind(this);
        this.handleClickItemCall = this.handleClickItemCall.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.track && (nextProps.track.getId() != this.state.track.getId()) || (nextProps.video_tag_id != this.state.video_tag_id)) {
          nextProps.track.attach($(`#${this.state.video_tag_id}`)[0]);
        }
        var tracksMuted=nextProps.tracksMuted
        let devices=tracksMuted[nextProps.id]
        var content=[]
        if(devices && devices[Str.STR_VIDEO] ) {
            content.push(<VideocamOffOutlinedIcon />)
        }
        if(devices && devices[Str.STR_AUDIO]) {
            content.push(<MicOffOutlinedIcon/>)
        }

        var connectionQuality;
        if(nextProps.remoteStates && nextProps.remoteStates[this.props.id]){
            connectionQuality=parseInt(nextProps.remoteStates[this.props.id].connectionQuality)
        }
        let connectionQualityColor, connectionQualityText;
        if(!connectionQuality || connectionQuality === 0) {
          connectionQualityColor = "white";
          connectionQualityText = "Loading..."
        } else if (connectionQuality < 30) {
          connectionQualityColor = "#D91E18"
          connectionQualityText = "Very poor"
        } else if (connectionQuality < 60) {
          connectionQualityColor = "#f3c200"
          connectionQualityText = "Poor"
        } else if (connectionQuality < 90) {
          connectionQualityColor = "#26C281"
          connectionQualityText = "Good"
        } else {
          connectionQualityColor = "#26C281"
          connectionQualityText = "Excellent"
        }
        this.setState({
            track: nextProps.track,
            othertrack: nextProps.othertrack,
            video_tag_id: nextProps.video_tag_id,
            locationName: nextProps.locationName,
            index: nextProps.index,
            calltag: nextProps.calltag,
            calltagColor: nextProps.calltagColor,
            status: nextProps.status,
            localUserData: nextProps.localUserData,
            width:nextProps.width,
            height:nextProps.height,
            tracksMutedContent:content,
            connectionQualityColor,
            connectionQualityText,
            connectionQuality: connectionQuality,
        });
        // Causes the video to blink every time any state changes in the entire project
        // if (nextProps.track) {
        //   nextProps.track.attach($(`#${this.state.video_tag_id}`)[0]);
        // }

        if (nextProps.status === Constant.INCOMING && nextProps.othertrack === null && this.alarmTimer === null) {
          setTimeout(() => { // if no timeout, first alarm wont play audio.
            this.alarm();
          }, 100);
          this.alarmTimer = setInterval(this.alarm, 4000);
        } else {
            if (this.alarmTimer) {
                clearInterval(this.alarmTimer);
                this.alarmTimer = null;
            }
        }
    }

    componentDidMount() {
        this.audio = new Audio(AlarmAudio);
        this.audio.load();

        this.count();
        this.countTimer = setInterval(this.count, 1000);
        if (this.state.track !== null) {
            this.state.track.attach($(`#${this.state.video_tag_id}`)[0]);
        }
    }

    componentWillUnmount() {
        clearInterval(this.countTimer);
        if (this.state.track !== null) {
            this.state.track.detach($(`#${this.state.video_tag_id}`)[0]);
        }

        if (this.alarmTimer) {
            clearInterval(this.alarmTimer);
            this.alarmTimer = null;
        }
    }

    handleClickItem() {
        let ret = false;
        if (this.state.track === null) {
            return;
        }
        if (this.state.othertrack === null  || this.state.othertrack.getParticipantId()===null ) {
            this.props.handleClickLocationItem(this.state.index);
            ret = true;
        }
        else if (this.state.localUserData.videotrack !== undefined) {
            if (this.state.othertrack.getParticipantId() === this.state.localUserData.videotrack.getParticipantId()) {
                this.props.handleClickLocationItem(this.state.index);
                ret = true;
            }
        }

        return ret;
    }

    handleClickItemCall(event) {
        event.preventDefault();

        let flag;
        flag = this.handleClickItem();
        if (flag === true) {
            this.props.handleItemCall();
        }
    }

    getTimeString(time) {
        var ret = '';
        var days = Math.floor(time / 60 / 60 / 24);
        var hours = Math.floor((time / 60 / 60) % 24);
        var minutes = Math.floor((time / 60) % 60);
        var seconds = time % 60;
        if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
            return '0 s';
        }
        if (days > 0) {
            ret = days + ' d ';
        }
        if (hours > 0) {
            ret = ret + hours + ' h ';
        }
        if (minutes > 0) {
            ret = ret + minutes + ' m ';
        }
        if (seconds > 0) {
            ret = ret + seconds + ' s';
        }
        return ret;
    }

    count() {
        if (this.state.status !== Constant.HOLD) {
            this.setState({ holdtime: Constant.ZERO });
            return;
        }
        this.setState(prevState => { return { holdtime: prevState.holdtime + 1 } });
    }

    alarm() {
        if(this.alarmTimer === null){
            return;
        }
        this.audio.play();
    }
    render() {
        var tagColor, tagName, durationTime;

        tagName = this.state.calltag;
        tagColor = this.state.calltagColor;
        if(!tagColor){
            switch (this.state.calltag) {
                case Str.STR_NORMAL:
                    tagColor = UserColor.GREEN_COLOR;
                    break;
                case Str.STR_NEW_PATIENT:
                    tagColor = UserColor.YELLOW_COLOR;
                    break;
                case Str.STR_NO_APPOINTMENT:
                    tagColor = UserColor.RED_COLOR;
                    break;
                case Str.STR_SELF_CHECK:
                    tagColor = UserColor.BLUE_COLOR;
                    break;
                case Str.STR_INITIAL_EVAL:
                    tagColor = UserColor.YELLOW_COLOR;
                    break;
                case Str.STR_CHECK_IN:
                    tagColor = UserColor.BLUE_COLOR;
                case Str.KIOSK:
                    tagColor = UserColor.YELLOW_COLOR;
                    break;
                default:
                    tagName = Str.STR_EMPUTY;
            }
        }
        durationTime = this.getTimeString(this.state.holdtime);

        return (
          <React.Fragment>
            <div className='patient_area' onClick={this.handleClickItem}>
                <div className={this.state.othertrack === null && this.state.status === Constant.INCOMING ? 'camera_area animate' : 'camera_area stop'}>
                    <video className='patient_img' autoPlay='1' id={this.state.video_tag_id} playsInline  
                      style={{width:this.state.width, height:this.state.height}}/>
                    {
                        this.state.status === Constant.INCOMING ?
                            <div className={this.state.othertrack === null ? 'other_div show' : 'other_div hide'}>
                                <Button className='hi_btn' onClick={this.handleClickItemCall}>Say Hi</Button>
                            </div>
                            :
                            null
                    }
                    <div className={this.state.status === Constant.HOLD ? 'over_area show' : 'over_area hide'}>
                        <div className='over_container'>
                            <div className='over_opacity_area'></div>
                            <img className='hold_call_img' src={CallIcon} alt='hold_call_img' />
                            <div className='hold_info_area'>
                                <div className='hold_time_area'>
                                    <label className='hold_lab' id='hold_time'>{durationTime}</label>
                                </div>
                                <label className='hold_lab'>ON HOLD</label>
                            </div>
                        </div>
                    </div>
                    {
                        tagName === Str.STR_EMPUTY || this.state.status === Constant.HOLD ?
                            null
                            :
                            <div className='calltag_div' style={{ backgroundColor: tagColor }}>
                                <label className='calltag_lab' style={{ color: tagName === Str.STR_NORMAL ? 'black' : 'white' }}>{tagName}</label>
                            </div>
                    }
                </div>
            <Tooltip title={`Reload ${this.state.locationName}`}>
                <ButtonBase style={{          backgroundColor:"#1C1D1E",
                    color:"white",
                    // display:"none",
                    position:"absolute",
                    top:0,
                    right:0,
                    borderRadius:"5px",
                    padding:"3px"
                }} 
                onClick={event=>this.props.reloadLocation(event,this.props.username)}>
                    <ReplayOutlinedIcon/>
                </ButtonBase>
            </Tooltip>
                {/* <label className='patient_name'>{this.state.locationName}</label> */}
              <div style={{
                  backgroundColor:"#1C1D1E",
                  color:"white",
                  position:"absolute",
                  bottom:0,
                  left:0,
                  borderRadius:"5px",
                  padding:"3px",
                  alignItems:"center",
              }}>
                  <label style={{margin:'0 5px'}}>
                  {this.state.tracksMutedContent} {this.state.locationName}
                  </label>
              </div>
              <Tooltip title={`${this.state.locationName} connection quality ${this.state.connectionQuality}`}>
                <span className='pl-0 py-1 d-flex' 
                  style={{
                    color: this.state.connectionQualityColor,
                    backgroundColor:"#1C1D1E",
                    position:"absolute",
                    top:0,
                    left:0,
                    borderRadius:"5px",
                    padding:"3px",
                    alignItems:"center",
                  }}>
                  <SignalCellularAltIcon/>
                  <label style={{margin:'0 5px'}}>
                    {this.state.connectionQualityText}
                  </label>
                </span>
              </Tooltip>
            </div>
          </React.Fragment>

        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        tracksMuted:state.tracksMuted,
        remoteStates: state.remoteStates
    }
}

export default connect(mapStateToProps,{})(RemoteLocationVideoView)
