import React, { useEffect, useCallback, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/styles';
import LocalUserView from './LocalUserView';
import RemoteUserView from './RemoteUserView';
import { useSelector, useDispatch } from 'react-redux';
import { addMic, selectMic, deleteMic, deleteMics, updateDefaultMic } from '../../redux/micSlice';
import { addCamera, selectCamera, deleteCamera, deleteCameras } from '../../redux/cameraSlice';
import { addSpeaker, selectSpeaker, deleteSpeaker, deleteSpeakers,updateSpeaker } from '../../redux/speaker';
import Str from '../../constants/string';
import Constant from '../../constants/constant';
import Storages from '../../constants/storages';
import { ButtonBase } from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Messanger from '../Reception/Messanger';
import { useMemo } from 'react';
import { useContext } from 'react';
import { SocketContext } from '../../context/socket';
import { addMessage ,addUnReadMessage, setUnReadMessages} from '../../redux/messages';
import apiService from "../../services/api"
import { toast } from 'react-toastify';
import AudioMessage from '../../assets/sounds/tone.mp3'
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';

const useStyle = makeStyles(({palette,mode}) => ({
    root: {
        display: 'inline-flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '0 1rem',
        backgroundColor:palette.background[mode],
        color:palette.color[mode]
    },
    close_area:{
        width:'100%',
        display:"flex",
        justifyContent: "space-between"
    },
    stage_area: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        borderBottom: '1px solid #00000042',


        
    },
    attendant_area: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        paddingTop:"10px",
        paddingBottom:"10px",
        overflowY:'auto'
    },
    video_area: {
        display: 'flex',
        width: '100%',
        height: '78%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    doctor_base_img: {
        maxWidth: '90%',
        maxHeight: '90%',
        display: 'block',
        border: '6px solid #007cbb'
    },
    main_video: {
        display: 'block',
        width: '100%',
        height: '200px',
        background: 'black',
        border: '1px solid black',
        borderRadius:"5px"
    },
    bottom_area: {
        display: 'flex',
        width: '100%',
        padding: '5px 0',
        flexDirection: 'row',
        // justifyContent: 'center',
        alignItems: 'center'
    },
    info_area: {
        display: 'flex',
        flexDirection: 'row',
        width: '70%',
    },
    feature_area: {
        display: 'flex',
        width: '30%'
    },
    user_name: {
        fontSize: 'max(1.14vw, 16px)',
        // fontFamily: 'Poppins',
    },
    time_area: {
        display: 'flex',
        alignItems: 'flex-end',
        marginLeft: '20px'
    },
    online_lab: {
        // fontFamily: 'poppins',
        fontSize: 'max(0.93vw, 14px)',
        marginRight: '5px'
    },
    title_area: {
        alignItems: 'center',
        display: 'flex',
        // height: '15.8%'
    },
    list_area: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingTop:"5px"
        // overflow: 'scroll',
    },
    attendant_lab: {
        // fontFamily: 'SemiBold',
        fontWeight: 'bold',
        fontSize: 'max(1.35vw, 18px)',
        margin: '0px'
    },
}))

const UserArea = React.memo((props) => {
    const { userName, userStatus, remoteUsers, joinState,handleToggleUserSideBar, localConnectionQuality } = props;
    const classes = useStyle();

    const dispatch = useDispatch();
    const mics = useSelector((state) => state.mics);
    const cameras = useSelector((state) => state.cameras);
    const speakers = useSelector((state) => state.speakers);
    const [checkDevice, setCheckDevice] = useState(false);
    const [updateCameraDevice, setUpdateCameraDevice] = useState(false);
    const [updateMicDevice, setUpdateMicDevice] = useState(false);
    const [displayChatBox,setDisplayChatBox]=useState(false)
    const [connectionQualityColor, setConnectionQualityColor]=useState("")
    const [connectionQualityText, setConnectionQualityText]=useState("")
    const [chatBy,setChatBy]=useState('')
    const chatByRef=useRef(chatBy)
    const _setChatByRef=(data)=>{
        chatByRef.current=data
        setChatBy(data)
    }
    const socket = useContext(SocketContext)
    const [chatByDisplayName,setChatByDisplayName]=useState('')
    const MessangerElement=useMemo(()=>Messanger,[displayChatBox,chatBy,chatByDisplayName])
    const [audio]=useState(new Audio(AudioMessage))

    useEffect(() => {
        // check devices timer
        let intervalDevices = setInterval(() => {
            setCheckDevice(prev => !prev);
        }, Constant.FIVE_SECONDS)
        socket.on('msg', async ({from,event, msg})=>{
            if(event==='chat'){
              try{
                var read_at=false
                if(from !==chatByRef.current) {
                    dispatch(addUnReadMessage({reception:from}))     
                    audio.play()
                }        
                else {
                    read_at=true
                    await apiService.readMessage({id:msg.id})
                }
                dispatch(addMessage({reception:from,message:{...msg,read_at}}))
              } catch (err) {
                console.error(err)
                toast.error(err.message, {autoClose: 10000})
              }
            }
        })
        return (() => {
            clearInterval(intervalDevices);
            socket.off('msg')
        })
    }, [])

    const handleCheckDevice = useCallback(() => {
        if (!joinState) {
            return;
        }
        const currentCameras = [...cameras];
        const currentMics = [...mics];
        var currentSpeakers = [...speakers];

        if (window.JitsiMeetJS.mediaDevices.isDeviceChangeAvailable(Str.STR_INPUT)) {
            window.JitsiMeetJS.mediaDevices.enumerateDevices((devices) => {
                const videoInputDevices = devices.filter((d) => d.kind === Str.STR_VIDEO_INPUT);
                const audioInputDevices = devices.filter((d) => d.kind === Str.STR_AUDIO_INPUT);
                const audioOutputDevices = devices.filter((d) => d.kind === Str.STR_AUDIO_OUTPUT);
                //add camera devices in redux when it is connected
                videoInputDevices.forEach((cell, i) => {
                    const index = currentCameras.findIndex((camera) => camera.deviceId === cell.deviceId)
                    if (index < 0) {
                        const item = { deviceId: cell.deviceId, label: cell.label, selected: false }
                        dispatch(addCamera(item));
                        if (currentCameras.length === 0 && i === 0) {
                            let cameraDeviceId = videoInputDevices[0].deviceId;
                            dispatch(selectCamera({ index: 0 }));
                            // localStorage.setItem(Storages.LOCAL_CAMERA_ID,cameraDeviceId)

                            // We don't have this for mics, why should we have it for camera?
                            // window.JitsiMeetJS.createLocalTracks({
                            //     devices: [Str.STR_VIDEO],
                            //     cameraDeviceId: cameraDeviceId
                            // })
                            // .then((tracks) => props.onLocalTracks(tracks))
                            // .catch(error => {
                            //     handleErrorTrack(error,1496,'camera')
                            // //   console.error(1496, error)
                            //   props.setCamera(false)
                            // //   toast.error(`There is an issue with video track. Please reload. 
                            // //     If this issue persists, please contact customer support.
                            // //   `)
                            // });
                        }
                    }
                })

                //delect camera device in redux when it is disconnected
                let isSelectedCamera = true;
                if (videoInputDevices.length > 0) {
                    currentCameras.forEach((cell) => {
                        const index = videoInputDevices.findIndex((camera) => camera.deviceId === cell.deviceId)
                        if (index < 0) {
                            dispatch(deleteCamera(cell));
                            if (cell.selected) {
                                isSelectedCamera = false;
                            }
                        }
                    })
                } else {
                  props.setCamera(false)
                  toast.error(`No camera found. Please reload. 
                    If this issue persists, please contact customer support.
                  `)

                  if (currentCameras.length > 0) {
                    dispatch(deleteCameras());
                  }
                }

                // if (!isSelectedCamera) {
                //     setUpdateCameraDevice(prev => !prev);
                // }



                // microphone
                if (audioInputDevices.length > 0) {
                    audioInputDevices.forEach((cell) => {
                      const index = currentMics.findIndex((mic) => mic.label === cell.label)
                      if (index < 0) {
                        if(cell.deviceId == "communications") {
                            return;
                        }
                        let newMic={
                          deviceId: cell.deviceId,
                          label: cell.label,
                        }
                        dispatch(addMic(newMic));
                      }
                    })
                    currentMics.forEach((m)=>{
                      let index=audioInputDevices.findIndex((mic) => mic.label === m.label)
                      if(index<0){
                        if(m.deviceId==='default'){
                          let input=audioInputDevices.find((mic) => mic.deviceId==='default')
                          dispatch(updateDefaultMic({deviceId:input.deviceId,label:input.label}))
                          return
                        }
                        dispatch(deleteMic({label:m.label}))
                        if(m.selected){
                          dispatch(selectMic({deviceId:audioInputDevices[0].deviceId}))
                        //   localStorage.setItem(Storages.LOCAL_MIC_ID,audioInputDevices[0].deviceId)
                        }
                      }
                    })
                }
                else {
                    if (currentMics.length > 0){
                    dispatch(deleteMics());
                    }
                }

                // speaker
                if (audioOutputDevices.length > 0) {
                    //add
                    audioOutputDevices.forEach((cell)=>{
                        const index = currentSpeakers.findIndex((s) => s.label === cell.label)
                        if (index < 0) {
                        if(cell.deviceId == "communications") {
                            return;
                        }
                        let label = cell.label;
                        let sp = {  deviceId: cell.deviceId, label}
                            dispatch(addSpeaker(sp));
                        }
                    })
                    // remove
                    currentSpeakers.forEach((sp)=>{
                        let index=audioOutputDevices.findIndex((s) => s.label === sp.label)
                        if(index<0){
                            if(sp.deviceId==='default'){
                            let out=audioOutputDevices.find((ao)=>ao.deviceId==='default')
                            if(out && out.label){
                                dispatch(updateSpeaker({deviceId:sp.deviceId,label:out.label}))
                            }
                            return
                            }
                            dispatch(deleteSpeaker({deviceId:sp.deviceId}))
                            if(sp.selected){
                            let deviceId=audioOutputDevices[0].deviceId
                            localStorage.setItem(Storages.LOCAL_SPEAKER_ID,deviceId)
                            dispatch(selectSpeaker({deviceId}))
                            document.getElementById('locationAudio').setSinkId(deviceId)
                            document.getElementById('mainRAudio').setSinkId(deviceId)
                            document.getElementById("testSpeaker").setSinkId(deviceId)
                            }
                        }
                    })
                }
                else if (currentSpeakers.length > 0){
                    dispatch(deleteSpeakers());
                }
            })
        }
    }, [checkDevice])

    useEffect(() => {
        handleCheckDevice();
    }, [checkDevice])


    // const handleUpdateCameraDevice = useCallback(() => {
    //     if (cameras.length > 0 && joinState) {

    //       let cameraDeviceId = localStorage.getItem(Storages.LOCAL_CAMERA_ID)
    //       if(!cameraDeviceId){
    //           cameraDeviceId = cameras[0].deviceId;
    //         //   localStorage.setItem(Storages.LOCAL_CAMERA_ID,cameraDeviceId)
    //           dispatch(selectCamera({ deviceId: cameraDeviceId }));
    //       }
    //         window.JitsiMeetJS.createLocalTracks({
    //             devices: [Str.STR_VIDEO],
    //             cameraDeviceId: cameraDeviceId
    //         })
    //         .then((tracks) => props.onLocalTracks(tracks))
    //         .catch(error => handleErrorTrack(error,1222,'camera'));
    //     }
    // }, [updateCameraDevice])

    // useEffect(() => {
    //     handleUpdateCameraDevice();
    // }, [updateCameraDevice])

    // const handleUpdateMicDevice = useCallback(() => {
    //     if (mics.length > 0 && joinState) {
    //         let micDeviceId = mics[0].deviceId;
    //         dispatch(selectMic({ index: 0 }));
    //         window.JitsiMeetJS.createLocalTracks({
    //             devices: [Str.STR_AUDIO],
    //             micDeviceId: micDeviceId
    //         })
    //         .then((tracks) => props.onLocalTracks(tracks))
    //         .catch(error => handleErrorTrack(error,1221,'microphone'));
    //     }
    // }, [updateMicDevice])

    // useEffect(() => {
    //     handleUpdateMicDevice();
    // }, [updateMicDevice])

    useEffect(() => {
      if(localConnectionQuality === 0) {
        setConnectionQualityColor("")
        setConnectionQualityText("Loading...")
      } else if (localConnectionQuality < 30) {
        setConnectionQualityColor("#D91E18")
        setConnectionQualityText("Very poor")
      } else if (localConnectionQuality < 60) {
        setConnectionQualityColor("#f3c200")
        setConnectionQualityText("Poor")
      } else if (localConnectionQuality < 90) {
        setConnectionQualityColor("#26C281")
        setConnectionQualityText("Good")
      } else {
        setConnectionQualityColor("#26C281")
        setConnectionQualityText("Excellent")
      }
    }, [localConnectionQuality])

    const handleCloseChat=()=>{
        setDisplayChatBox(false)
        _setChatByRef('')
        setChatByDisplayName('')
    }
    const handleOpenChatBox=async(person,displayName)=>{
        _setChatByRef(person)
        setChatByDisplayName(displayName)
        setDisplayChatBox(true)
        dispatch(setUnReadMessages({reception:person,number:0}))
    }

    const handleErrorTrack=(err,code,device)=>{
        console.error(code,device,err)
        toast.error(device," : ",err.message,5000);
      }
    return (
        <div className={classes.root}>
            <div className={classes.close_area} >
                <span className='pl-0 py-1 d-flex' style={{color: connectionQualityColor}} 
                  title={`Connection quality ${localConnectionQuality}`}>
                  <SignalCellularAltIcon/>
                  {connectionQualityText}
                </span>
                <ButtonBase className='px-2 py-1'>
                  <CloseOutlinedIcon onClick={handleToggleUserSideBar}/>
                </ButtonBase>
            </div>
            <div className={classes.stage_area}>

                <video className={classes.main_video} autoPlay='1' id='mainRVideo' playsInline />
                <audio autoPlay='1' muted='1' id='mainRAudio' />
                <div className={classes.bottom_area}>
                    <div className={classes.info_area}>
                        <LocalUserView changeUserStatus={() => props.changeUserStatus()} user_name={userName} user_status={userStatus} />
                    </div>
                    {/* <div className={classes.feature_area}>
                        <DeviceControlArea
                            onClickCamera={() => props.handleLocalCamera()}
                            onClickMic={() => props.handleLocalMic()}
                            mic={mic}
                            camera={camera}
                        />
                    </div> */}
                </div>
            </div>
            <div className={classes.attendant_area}>
                <div className={classes.title_area}>
                    <label className={classes.attendant_lab}>Attendants</label>
                </div>
                <div className={classes.list_area}>
                {remoteUsers.map((remoteUser, index) => (
                        <RemoteUserView 
                          key={index} 
                          user_name={remoteUser.username} 
                          display_Name={remoteUser.displayName} 
                          user_status={remoteUser.status} 
                          location_name={remoteUser.otherpartid} 
                          handleOpenChat={handleOpenChatBox}
                        />
                    ))}
                </div>
            </div>
            <MessangerElement display={displayChatBox} handleCloseChat={handleCloseChat} reception={chatBy} displayName={chatByDisplayName}/>
        </div>
    )
});

export default UserArea;