import React from "react";
import { makeStyles} from "@material-ui/core/styles";
import { useEffect } from "react";
import { useSelector } from 'react-redux';
import { useState } from "react";
import String from '../../constants/string';
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined';
const useStyles = makeStyles({
    main:{
        backgroundColor:"#404040",
        position:"absolute",
        bottom:0,
        borderRadius:"5px",
        padding:"5px 10px 5px 10px",
        marginBottom:"6rem",
        color:"white",
        minWidth:"5vw",
        justifyContent:"center",
        display:"flex",
        alignItems:"center"
    },
});

export default function LocationNameBox ({name, id, allLocations}){
    const classes = useStyles();
    const tracksMuted = useSelector((state) => state.tracksMuted)
    const [tracksMutedContent,setTracksMutedContent] = useState([])
    const [locationDisconnected, setLocationDisconnected] = useState(false)

    useEffect(()=>{
        if(id){
            var content=[]
            let devices=tracksMuted[id]
            if(devices && devices[String.STR_VIDEO] ) {
                content.push(<VideocamOffOutlinedIcon />)
            }
            if(devices && devices[String.STR_AUDIO]) {
                content.push(<MicOffOutlinedIcon/>)
            }
            setTracksMutedContent(content)
        }
    },[tracksMuted,id])

    useEffect(() => {
      if(Array.isArray(allLocations) && id) {
        setLocationDisconnected(allLocations.find(item => item.locationname == name)?.isOnline == 0)
      }
    }, [allLocations, id])

    return (
        <>
            {name?
                <div className={classes.main}>
                    <h5 className="mb-0 text-center">{tracksMutedContent} {name} {locationDisconnected && <span className="text-warning"><br/>Connection issues</span>}</h5>
                </div>
                :null
            }

        </>

    );
}
 
