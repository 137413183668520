import React ,{useState,useEffect, useRef, useContext} from 'react'
import {Modal,Col,Row} from 'react-bootstrap';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SettingsIcon from '@material-ui/icons/Settings';
import VideocamIcon from '@material-ui/icons/Videocam';
import VolumeDownIcon from '@material-ui/icons/VolumeDown';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined'; 
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import {ThemeToggleContext} from '../../context/theme'
import Brightness4Icon from '@material-ui/icons/Brightness4';
import {setShowSecondaryLocations} from '../../redux/showSecondaryLocations';
import { useSelector } from 'react-redux';
import redux from '../../redux/store';
import Switch from '@material-ui/core/Switch';
import { withStyles , makeStyles} from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import { ListItemText } from "@material-ui/core";
import AudioDevices from '../Controls/AudioDevices';
import CameraDevices from '../Controls/CameraDevices';
import Account from './Account';
import AdminService from '../../services/api'
const SecondarySwitch = withStyles({
  switchBase: {
    color: "#f3c200",
    '&$checked': {
      color: "#F4D03F",
    },
    '&$checked + $track': {
      backgroundColor: "#F4D03F",
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles(({palette,mode}) => ({
  root: {
    '& .modal-content':{
      backgroundColor:palette.background[mode] + " !important",
      color:palette.color[mode] + " !important"
    },
    '& .modal-header .close' : {
      color:palette.color[mode] + " !important"
    }
  },
  item:{
    color:palette.color[mode]
  },
  verticalDivider: {
    borderRight: "1px solid",
    borderColor: palette.lightGray[mode] + " !important",
  },
  modalBody: {
    height: "50vh",
    '@media (max-height: 700px)': {
      height: "60vh"
    },
    overflow: "auto"
  }
}));

export const SettingsModal=({
    showModal,
    handleClose,
    toggleBlur,
    callState,
    nonElectronUserLeave,
})=>{
  const [index,setIndex]=useState(0)
  const {toggleMode, mode} = useContext(ThemeToggleContext)
  const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
  const [showToggleSecondary, setShowToggleSecondary] = useState(false);
  const [isBlurNow, setIsBlurNow] = useState(false);
  const classes=useStyles()

  useEffect(()=>{
    setShowToggleSecondary(localStorage.getItem("showToggleSecondary"))
    setIsBlurNow(localStorage.getItem("blurMyBackground"))
    setIndex(0)
  },[showModal])

  const handleListItemClick=(ev,val)=>{
    setIndex(val)
  }


  const handleReload=()=>{
      if(callState){
        window.confirmAsync.show(
          <h6 style={{fontSize: "1.15rem", marginBottom: "0px"}}>Reload</h6>, 
          <span style={{fontSize: "1.05rem", marginBottom: "0px"}}>
            Call in progress. Are you sure?
          </span>, 
          [
            { value: 1, color: "primary", text: "Yes", close: 1 }, 
            { value: 0, color: "default", text: "No", close: 1 }
          ],'md'
        ).then((value) => {
          if(value === 1) {
            AdminService.createActivityLog({
              tags:['reload','reception'],
              title:"reload",
              details:"Reception reloaded application when call in progress."
            })
            if(window.electron) {
              window.electron.reload();
            } else {
              window.location.replaceWithoutWarn("#/")
            }
          }
        })
      } else {
        AdminService.createActivityLog({
          tags:['reload','reception'],
          title:"reload",
          details:"Reception reloaded application."
        })
        if(window.electron) {
          window.electron.reload();
        } else {
          window.location.replaceWithoutWarn("#/")
        }
      }
      handleClose()
  }

  const handleToggleDarkMode=()=>{
    toggleMode()
  }

  const handleExit=()=>{
    if(window.electron) {
      window.electron.closeApp();
    } else {
      nonElectronUserLeave();
    }
  }

  const toggleSecondary = () => {
    if(showSecondaryLocations) {
      localStorage.removeItem("showSecondaryLocations")
      redux.dispatch(setShowSecondaryLocations(false))
    } else {
      localStorage.setItem("showSecondaryLocations", "1")
      redux.dispatch(setShowSecondaryLocations(true))
    }
  }

  const toggleBlurLocal = () => {
    setIsBlurNow((prev) => !prev)
    toggleBlur();
  }

    return (
      <>
        <Modal className={classes.root} show={showModal} size='lg' onHide={handleClose} keyboard={false} centered >
           <Modal.Header closeButton>
                <Modal.Title>Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body className={classes.modalBody + " show-grid"}>
              <Row>
                <Col className={classes.verticalDivider} xs={4} md={4}>
                  <ListItem button key={'General'} selected={index===0}  onClick={(event) => handleListItemClick(event, 0)}>
                      <ListItemIcon> <SettingsIcon className={classes.item} /> </ListItemIcon>
                      <ListItemText className={classes.item} primary={
                        <span className={'h6 ' + classes.item}>General</span>
                      }/>
                  </ListItem>
                  <ListItem button key={'Audio'} selected={index===1}  onClick={(event) => handleListItemClick(event, 1)}>
                      <ListItemIcon> <VolumeDownIcon className={classes.item} /> </ListItemIcon>
                      <ListItemText className={classes.item} primary={
                        <span className={'h6 ' + classes.item}>Audio</span>
                      }/>
                  </ListItem>
                  <ListItem button key={'Video'} selected={index===2}  onClick={(event) => handleListItemClick(event, 2)}>
                      <ListItemIcon> <VideocamIcon className={classes.item} /> </ListItemIcon>
                      <ListItemText className={classes.item} primary={
                        <span className={'h6 ' + classes.item}>Video</span>
                      }/>
                  </ListItem>
                  <ListItem button key={'Account'} selected={index===3}  onClick={(event) => handleListItemClick(event, 3)}>
                      <ListItemIcon> <AccountCircleIcon className={classes.item} /> </ListItemIcon>
                      <ListItemText className={classes.item} primary={
                        <span className={'h6 ' + classes.item}>Account</span>
                      }/>
                  </ListItem>
                </Col>
                <Col xs={8} md={8}>
                  {index===0?
                    <div className='d-flex flex-column'>
                      <ListItem button key={'Blur-background'} onClick={toggleBlurLocal} className='pl-2'
                        title={isBlurNow ? "Remove my video blur" : "Blur my video background"}>
                        <ListItemIcon >
                          <Switch
                            className={classes.item}
                            checked={isBlurNow}
                            size='small'
                          />
                        </ListItemIcon>
                        <ListItemText primary={
                          <span className={"pl-2 " + classes.item}>
                            {isBlurNow ? "Remove blur" : "Blur background"}
                          </span>
                        }/>
                      </ListItem>
                      {showToggleSecondary && 
                        <ListItem button key={'Show-secondary'} onClick={toggleSecondary} className='pl-2'
                          title= {showSecondaryLocations ? "Hide secondary locations (alternates)" : "Show secondary locations (alternates)"}>
                          <ListItemIcon >
                            <SecondarySwitch
                              className={classes.item}
                              checked={showSecondaryLocations}
                              size='small'
                              />
                          </ListItemIcon>
                          <ListItemText primary={
                            <span className={"pl-2 " + classes.item}>
                              {showSecondaryLocations ? "Hide secondary" : "Show secondary"}
                            </span>
                          }/>
                        </ListItem>
                      }
                      <ListItem  button key={'Theme'} onClick={handleToggleDarkMode}>
                          <ListItemIcon > <Brightness4Icon className={classes.item} /> </ListItemIcon>
                          <ListItemText primary={
                            <span className={classes.item}>
                              {(mode==='dark')? "Light Mode" : "Dark Mode" }
                            </span>
                          }/>
                      </ListItem>
                      <ListItem button key={'Reload'} onClick={handleReload}>
                          <ListItemIcon > <ReplayOutlinedIcon className={classes.item} /> </ListItemIcon>
                          <ListItemText primary={
                            <span className={classes.item}>
                              Reload
                            </span>
                          }/>
                      </ListItem>
                      <ListItem button key={'Exit-App'} onClick={handleExit}>
                          <ListItemIcon > <ExitToAppOutlinedIcon className={classes.item} /> </ListItemIcon>
                          <ListItemText primary={
                            <span className={classes.item}>
                              {window.electron ? "Exit" : "Logout"}
                            </span>
                          }/>
                      </ListItem>
                    </div>:""
                  }
                  {index===1 &&
                    <AudioDevices show={ index===1} audiosID={["locationAudio","mainRAudio","alarmAudio"]}/>
                  } 
                  { index===2 &&
                    <CameraDevices show={index===2}/>
                  }
                  { index === 3 &&
                    <Account handleClose={handleClose}/>
                  }
                </Col>
              </Row>
            </Modal.Body>
        </Modal>
      </>

    )
}