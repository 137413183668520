import React from "react";
import { makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Storages from '../../constants/storages';
import CallTimeLabel from '../Controls/CallTimeLabel';
const useStyles = makeStyles({
    main:{
        backgroundColor:"#1C1D1E",
        position:"absolute",
        top:0,
        borderRadius:"5px",
        padding:"5px 10px 5px 10px",
        marginTop:"1rem",
        color:"white",
        minWidth:"5vw"
    }
});

export default function ReceptionInfo (){
    const classes = useStyles();
    const name=localStorage.getItem(Storages.LOCAL_RECEPTION_NAME)
    

    return (
        <div className={classes.main}>
            <Grid container   spacing={2} direction='row' justifyContent="center" alignItems="center">
                <Grid item className="text-capitalize">
                    {name}
                </Grid>
                <Grid item >

                    <CallTimeLabel fontSize={'1'} duringTime={0} play={true} />
                </Grid>
            </Grid>
        </div>
    );
}
 
