
import React, { useEffect, useState, memo } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
} from "@material-ui/core";
import apiService from '../../services/api';
import Draggable from 'react-draggable';
import { withStyles } from '@material-ui/core/styles';
import { yellow, red } from '@material-ui/core/colors';
import PrintIcon from '@material-ui/icons/Print';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { toast } from "react-toastify"
import axios from 'axios';
import tokenService from '../../services/tokenService';
import Modal from 'react-bootstrap/Modal';
import DraggableModal from './DraggableModal';

const YellowButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(yellow[700]),
    backgroundColor: yellow[500],
    '&:hover': {
      backgroundColor: '#ffff00',
    },
  },
}))(Button);

const RedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[700]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: '#ff0000',
    },
  },
}))(Button);

const useStyle = makeStyles(({palette,mode}) => ({
  modalFormControl: {
    '& label': {
      color: palette.color[mode],
    },
    '& label.Mui-disabled': {
      color: palette.lightGray[mode],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: palette.lightGray[mode],
      },
      '&:hover fieldset': {
        borderColor: palette.primary[mode],
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.primary[mode],
      },
      '&.Mui-disabled fieldset': {
        borderColor: palette.lightGray[mode],
      },
      '&.Mui-disabled .MuiSelect-selectMenu': {
        color: palette.lightGray[mode],
      },
    },
  },
  modalFormSelect: {
    backgroundColor:palette.background[mode] + " !important",
    color:palette.color[mode] + " !important",
  },
}))

const API_URL = process.env.REACT_APP_API_URL;
let token;

const PrintFileRequestModal = memo(({
  allLocations=[], 
  locationEngaged, 
  externalShowModal, 
  externalClose
})=>{
  const [showModal, setShowModal] = useState(false)
  const [files, setFiles] = useState([{id: -1, name: "Please choose"}])
  const [location, setLocation] = useState("");
  const [disableInputs, setDisableInputs] = useState(false);
  const [chosenFile, setChosenFile] = useState({id: -1, name: "Please choose"})
  const [loading, setLoading] = useState(false);
  const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
  const classes=useStyle()
  
  useEffect(() => {
    token = tokenService.get();
  }, [])

  useEffect(() => {
    setShowModal(externalShowModal)
  }, [externalShowModal])

  useEffect(() => {
    if (showModal) {
      readFilesList()
    }
  }, [showModal])

  useEffect(() => {
    setLocation(locationEngaged || "")
  }, [locationEngaged])

  async function readFilesList() {
    try{
      setLoading(true)
      if (window.electron) {
        let tmpFiles = [];
        const data = await window.electron.listFiles()
        for(let i in data) {
          tmpFiles.push({id: Number(i), name: data[i]})
        }
        setFiles(tmpFiles)
      } else {
        // -24 is a random number specific for 'Choose a local file'
        let tmpFiles = [{id: -24, name: "Choose a local file"}];
        setChosenFile({id: -24, name: "Choose a local file"})
        const apiCheckRes = await apiService.checkSharedFiles();
        const apiCheckData = apiCheckRes.data
        if(apiCheckData.code === 0 && apiCheckData.data) {
          for(let sFile of apiCheckData.data.newArr) {
            tmpFiles.push({id: sFile.id, name: `(Online) ${sFile.name}`})
          }
        } else {
          toast.error(apiCheckData.msg, {autoClose: 10 * 1000})
        }

        setFiles(tmpFiles);
      }
    } catch (err) {
      console.error(err)
      toast.error(err.message, {autoClose: 10 * 1000})
    } finally {
      setDisableInputs(false);
      setLoading(false)
    }
  }

  function onLocationChanged(event) {
    setLocation(event.target.value);
  }

  async function onChosenFileChange(fileID) {
    try{
      let tmpFiles = [...files]
      let file = tmpFiles.find(item => item.id === fileID)
      if(!file) {
        toast.error("No file found with this name, Please reload this modal and try again", {autoClose: 5 * 1000})
        return;
      }
      // this file was loaded before. So don't load it again
      if(file.dataUrl) {
        setChosenFile(file)
        return;
      }
      if(window.electron) {
        setDisableInputs(true);
        const readFileRes = await window.electron.readFile(file.name)
        if(readFileRes.code === 0) {
          const data = readFileRes.data //buffer
          // let blobRes = await fetch(`data:application/pdf;base64,${data}`)
          // let blob = await blobRes.blob()
          const objectUrl = URL.createObjectURL(new Blob([data], {type: "application/pdf"}));
          file.dataUrl = objectUrl + '#toolbar=0&navpanes=0&scrollbar=0'
        } else {
          toast.error(readFileRes.msg, {autoClose: 10000})
        }
        setDisableInputs(false);
        setChosenFile(file)
        setFiles(tmpFiles)
      } else {
        if(file.id === -24) {
          document.getElementById("printFileInput").click();
          setChosenFile(file)
          setFiles(tmpFiles)
        } else {
          setDisableInputs(true);
          
          const url = API_URL + `desktop/shared-files/${file.id}?token=${token}`
          const response = await axios.get(url, { responseType: 'arraybuffer' })
          const blob = new Blob([response.data], {type: "application/pdf"})
          const reader = new FileReader();
          reader.onloadend = () => {
            // dont user blob as dataUrl. doesn't work on firefox on production for some reason?!
            file.dataUrl = reader.result + '#toolbar=0&navpanes=0&scrollbar=0'
            setDisableInputs(false);
            setChosenFile(file)
            setFiles(tmpFiles)
          };
          reader.readAsDataURL(blob);
        }
      }

    } catch (err) {
      console.error(err)
      toast.error(err.message, {autoClose: 10 * 1000})
      setDisableInputs(false);
    }
  }

  function uploadNewFile(event) {
    const selectedFile = event.target.files[0];
    if(!selectedFile) {
      toast.error("No file selected. Please try again")
      return
    }
    
    setDisableInputs(true)

    let tmpFiles = [...files]
    let newFile = {id: Date.now(), name: selectedFile.name}
    const reader = new FileReader();
    // reader.readAsArrayBuffer(selectedFile);
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      // dont user blob as dataUrl. doesn't work on firefox on production for some reason?!
      newFile.dataUrl = reader.result + '#toolbar=0&navpanes=0&scrollbar=0'
      tmpFiles.push(newFile)
      setDisableInputs(false)
      setFiles(tmpFiles)
      setChosenFile(newFile)
    };
  }

  function close() {
    setShowModal(false)
    externalClose();
  }

  async function submit() {
    try{
      if (!location || !chosenFile || !chosenFile.dataUrl) {
        toast.error(`'location' and 'file' are required.`, {autoClose: 5000})
        return;
      }
      setDisableInputs(true);
      const fileBase64 = await fetch(chosenFile.dataUrl)
        .then((response) => response.arrayBuffer() )
        .then((arrayBuffer) => {return Buffer.from(arrayBuffer).toString('base64')})

      const axiosRes = await apiService.sendMessage({ to: location, event: "print", msg: { file: fileBase64 } });
      const printRes = axiosRes.data
      if (printRes.code === 0) {
        close();
        toast.success("Print request has been sent.");
      } else {
        toast.error(`Error while printing file: ${printRes.msg}`, {autoClose: 5000})
      }
    } catch (err) {
      console.error(err);
      toast.error(`Error while printing file: ${err.message}`, {autoClose: 5000})
    } finally {
      setDisableInputs(false)
    }
  }

  return (
    <DraggableModal 
      closeButton={true}
      onCloseButton={close}
      header={<h5 className="mb-0">Send file for print</h5>}
      body={<>
        {!window.electron && <input type='file' id='printFileInput' style={{display: "none"}} onChange={uploadNewFile} accept=".pdf,.docx,.txt,image/*,*/*"/>}
        {chosenFile && chosenFile.dataUrl ? 
          <FormControl fullWidth style={{height: "400px"}} className="mb-3">
            <object data={chosenFile.dataUrl} type="application/pdf" width="100%" height="100%"></object>
          </FormControl> 
        : ""}
        <FormControl fullWidth variant="outlined" className={classes.modalFormControl}>
          <InputLabel id="file-label" disabled={disableInputs}>Which file</InputLabel>
          <Select
            labelId="file-label"
            labelWidth={80}
            disabled={disableInputs}
            value={chosenFile.id}
            className={classes.modalFormSelect}
          >
            {
              loading ? <MenuItem value="">Loading files...</MenuItem> :
              Array.isArray(files) && !files[0] ? <MenuItem value="">No pdf file found.</MenuItem> :
              files.map((item, index) => 
                <MenuItem key={index} value={item.id} onClick={() => onChosenFileChange(item.id)}>
                  {item.name}
                </MenuItem>
              )
            }
          </Select>
        </FormControl>
        <FormControl fullWidth variant="outlined" className={'mt-3 ' + classes.modalFormControl}>
          <InputLabel id="location-label" disabled={disableInputs}>To Location</InputLabel>
          <Select
            labelId="location-label"
            onChange={onLocationChanged}
            labelWidth={80}
            value={location}
            disabled={disableInputs}
            className={classes.modalFormSelect}
          >
            <MenuItem value="">Please choose ...</MenuItem>
            {allLocations.map((item, index) => {
              const isSec = !item.isPrimary;
              if(isSec && !showSecondaryLocations) return;
              return (
                <MenuItem key={index} value={item.username}>
                  {item.locationname}
                  {isSec && 
                    <sup style={{color: "#f3c200", fontWeight: "900"}} title='Secondary location'>&nbsp;2nd</sup>
                  }
                </MenuItem>
              )
            })}
            </Select>
        </FormControl>
      </>}
      footer={<>
        <RedButton className="dialog_btn" variant="contained" color="secondary" 
          startIcon={<CloseIcon />} onClick={close}>Cancel</RedButton>
        <YellowButton className="dialog_btn" variant="contained" color="secondary" 
          startIcon={<PrintIcon />} onClick={submit} disabled={disableInputs}>Submit</YellowButton>
      </>}
      show={showModal}
    />
  )
})
export default PrintFileRequestModal