import React from "react";
import { useState, useRef } from "react";
import {
  FormControl,
  TextField,
  Button,
  withStyles,
} from "@material-ui/core";
import { green, red } from '@material-ui/core/colors';
import SaveIcon from '@material-ui/icons/Save';
import apiService from '../../services/api';
import { toast } from "react-toastify";
import DeleteIcon from "@material-ui/icons/Delete";
import storages from "../../constants/storages";
import { makeStyles } from "@material-ui/core/styles";
import tokenService from "../../services/tokenService";

const GreenButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[700]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: '#008800',
    },
  },
}))(Button);

const RedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[700]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: '#bb0000',
    },
  },
}))(Button);

const useStyles = makeStyles(({palette,mode}) => ({
  item:{
    '& label.Mui-focused': {
      color: palette.color[mode],
    },
    '& label': {
      color: palette.color[mode],
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: palette.color[mode],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: palette.lightGray[mode],
      },
      '&:hover fieldset': {
        borderColor: palette.primary[mode],
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.primary[mode],
      },
    },
  }
}));

export default function Account ({handleClose}){
    const [oldPassError, setOldPassError] = useState("");
    const [newPassError, setNewPassError] = useState("");
    const [newPassRepeatError, setNewPassRepeatError] = useState("");
    const oldPassRef = useRef();
    const newPassRef = useRef();
    const newPassRepeatRef = useRef();
    const hasSavedLogin = localStorage.getItem(storages.LOCAL_IS_REMEMBER)
    const classes=useStyles()

    const save = async ()=>{
      try{
        setOldPassError("")
        setNewPassError("")
        setNewPassRepeatError("")

        const oldPass = oldPassRef.current.value;
        const newPass = newPassRef.current.value;
        const newPassRepeat = newPassRepeatRef.current.value;
        let hasError = false;
        if(!oldPass) {
          setOldPassError("This field is required")
          hasError = true
        }
        if(!newPass) {
          setNewPassError("This field is required")
          hasError = true
        }
        if(!newPassRepeat) {
          setNewPassRepeatError("This field is required")
          hasError = true
        }
        if(hasError) return;

        if(newPass.length < 8) {
          setNewPassError("New password should be at least 8 characters")
          return
        }
        if(newPass !== newPassRepeat) {
          setNewPassRepeatError("This should be the same as new password")
          return
        }

        const updateRes = await apiService.updatePassword({oldPass, newPass, newPassRepeat})
        if(updateRes.data.code === 0) {
          toast.success("Updated successfully!")
          oldPassRef.current.value = "";
          newPassRef.current.value = "";
          newPassRepeatRef.current.value = "";
          handleClose();
        } else {
          toast.error(`${updateRes.data.msg}`, {autoClose: 10000})
        }
      } catch (err) {
        console.error(err)
        toast.error("An internal error ocurred", {autoClose: 10000})
      }
    }

    const deleteSavedLogin = () => {
      window.confirmAsync.show(
        <h6 style={{fontSize: "1.15rem", marginBottom: "0px"}}>Delete saved login</h6>, 
        <span style={{fontSize: "1.05rem", marginBottom: "0px"}}>
          Your saved Virtual Sally login on this device will be removed and you will be logged out. Are you sure?
        </span>, 
        [
          { value: 1, color: "primary", text: "Yes", close: 1 }, 
          { value: 0, color: "default", text: "No", close: 1 }
        ],'md'
      ).then((value) => {
        if(value === 1) {
          if(window.electron) {
            // we dont remove from sessionStorage because closeApp will call userLeave
            localStorage.removeItem(storages.LOCAL_IS_REMEMBER)
            localStorage.removeItem(storages.LOCAL_TOKEN_USER)
            window.electron.closeApp();
          } else {
            tokenService.remove()
            window.location.replaceWithoutWarn("#/")
          }
        }
      })
    }

    return (
      <div className="my-2">
        <label className="mb-0">
            Change password
        </label>
        <form>
          <FormControl fullWidth variant="outlined" className="mt-3">
            <TextField
              error={!!oldPassError}
              id="old-pass"
              label="Old password"
              inputRef={oldPassRef}
              variant="outlined"
              type="password"
              required
              helperText={oldPassError}
              className={classes.item}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined" className='mt-3'>
            <TextField
              error={!!newPassError}
              id="new-pass"
              label="New password"
              inputRef={newPassRef}
              variant="outlined"
              type="password"
              required
              helperText={newPassError}
              className={classes.item}
            />
          </FormControl>
          <FormControl fullWidth variant="outlined" className='mt-3'>
            <TextField
              error={!!newPassRepeatError}
              id="old-password"
              label="Repeat new password"
              inputRef={newPassRepeatRef}
              variant="outlined"
              type="password"
              required
              helperText={newPassRepeatError}
              className={classes.item}
            />
          </FormControl>
          <FormControl className='mt-3 float-right'>
            <GreenButton className="dialog_btn" variant="contained" 
              startIcon={<SaveIcon />} onClick={save}>
              Save
            </GreenButton>
          </FormControl>
          {hasSavedLogin && 
            <FormControl className='mt-3 float-left'>
              <RedButton className="dialog_btn" variant="contained" 
                startIcon={<DeleteIcon />} onClick={deleteSavedLogin}>
                Delete saved login
              </RedButton>
            </FormControl>
          }
        </form>
      </div>
    );
}

