
import React, { useEffect , useState, memo, useContext} from 'react';
import './modal-style.css';
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { yellow, red } from '@material-ui/core/colors';
import {SocketContext} from '../../context/socket'
import CloseIcon from '@material-ui/icons/Close';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import apiService from '../../services/api';
import { useSelector } from 'react-redux';
import {toast} from "react-toastify"
import DraggableModal from './DraggableModal';

const YellowButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(yellow[700]),
    backgroundColor: yellow[500],
    '&:hover': {
      backgroundColor: '#ffff00',
    },
  },
}))(Button);

const RedButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[700]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: '#ff0000',
    },
  },
}))(Button);

const useStyle = makeStyles(({palette,mode}) => ({
  modalFormControl: {
    '& label': {
      color: palette.color[mode],
    },
    '& label.Mui-disabled': {
      color: palette.lightGray[mode],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: palette.lightGray[mode],
      },
      '&:hover fieldset': {
        borderColor: palette.primary[mode],
      },
      '&.Mui-focused fieldset': {
        borderColor: palette.primary[mode],
      },
      '&.Mui-disabled fieldset': {
        borderColor: palette.lightGray[mode],
      },
      '&.Mui-disabled .MuiSelect-selectMenu': {
        color: palette.lightGray[mode],
      },
    },
  },
  modalFormSelect: {
    backgroundColor:palette.background[mode] + " !important",
    color:palette.color[mode] + " !important",
  }
}))

const CardDetectionRequest = memo(({allLocations, locationEngaged, open, handleClose})=>{
    const [showModal, setShowModal] = useState(false)
    const [location, setLocation] = useState("");
    const [cardDetectionLog, setCardDetectionLog] = useState("");
    const [disableInputs, setDisableInputs] = useState(false);
    const showSecondaryLocations = useSelector((state) => state.showSecondaryLocations)
    const socket = useContext(SocketContext)
    const classes=useStyle()

    useEffect(() => {
      socket.on('msg',({event, msg})=>{
        if(event === "card-detection-res") {
          if(msg.msg !== "Hold still!") {
            setDisableInputs(false);
          }
          setCardDetectionLog(msg.msg)
        }
      })

      return () => {
        socket.off("msg")
      }
    }, [])

    useEffect(() => {
      setCardDetectionLog("")
      setShowModal(open)
      setDisableInputs(false);
    }, [open])

    useEffect(() => {
      if(locationEngaged && locationEngaged !== location) {
        setLocation(locationEngaged)
      }
    },[locationEngaged])
    
    function onLocationChanged(event) {
      setLocation(event.target.value);
    }

    async function submit() {
      try{
        if(!location) {
          toast.error(`'location' is required.`, {autoClose: 5000})
          return;
        }

        setDisableInputs(true);
        setCardDetectionLog("Please wait...")

        await apiService.sendMessage({
          to: location,
          event: "card-detection-on"
        })

        setCardDetectionLog("Card detection started. Waiting for location")
      } catch (err) {
        console.error(err)
        toast.error(err.message, {autoClose: 10000})
      }
    }

    async function handleCancel() {
      apiService.sendMessage({
        to: location,
        event: "card-detection-off"
      }).catch((err) => {
        console.error(err);
      })

      setDisableInputs(false);
      setCardDetectionLog("")
      handleClose()
    }

    return (
      <DraggableModal
        closeButton={true}
        onCloseButton={handleCancel}
        header={<h5 className="mb-0">Card Detection</h5>}
        body={<>
          <FormControl fullWidth variant="outlined" className={classes.modalFormControl}>
            <InputLabel id="location-label" disabled={disableInputs}>To Location</InputLabel>
            <Select
              labelId="location-label"
              onChange={onLocationChanged}
              labelWidth={80}
              value={location}
              disabled={disableInputs}
              className={classes.modalFormSelect}
            >
              <MenuItem value="">Please choose ...</MenuItem>
              {allLocations.map((item, index) => {
                const isSec = !item.isPrimary;
                if(isSec && !showSecondaryLocations) return;
                return (
                  <MenuItem key={index} value={item.username}>
                    {item.locationname}
                    {isSec && 
                      <sup style={{color: "#f3c200", fontWeight: "900"}} title='Secondary location'>&nbsp;2nd</sup>
                    }
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <div style={{marginTop: "10px", marginBottom: "5px"}}>{cardDetectionLog}</div>
        </>}
        footer={<>
          <RedButton className="dialog_btn" variant="contained" color="secondary" 
            startIcon={<CloseIcon />} onClick={handleCancel}>Cancel</RedButton>
          <YellowButton className="dialog_btn" variant="contained" color="secondary" disabled={disableInputs}
            startIcon={<PageviewOutlinedIcon />} onClick={submit}>Start</YellowButton>
        </>}
        show={showModal}
      />
    )
})
export default CardDetectionRequest