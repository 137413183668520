import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import ColorButton from './ColorButton';
import Color from '../../constants/usercolor';
import './iconcolorbutton.css';
 
const useStyles = theme => ({
    root: {
        height: '5.6vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'transparent',
        borderRadius: '3vw',
        '& :hover': {
            opacity: '0.9',
            cursor: 'pointer'
        },
        '& :active': {
            opacity: '0.8'
        }
    },
    back_img: {
        width: '100%'
    }
});

class BackButton extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    componentWillUnmount() {

    }

    render() {
      const { classes } = this.props;
      return (
        <div className={'back_btn'} onClick={() => this.props.onClicked()} >
            <label className='icon_lab'>{"Back"}</label>
        </div>
      );
    }
}

export default withStyles(useStyles)(BackButton)