import React, { useEffect, useState } from 'react';
import { Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';

const useStyle = makeStyles(({palette,mode}) => ({
  modalFormButton: {
    color: palette.primary[mode],
    borderColor: palette.primary[mode],
    '&:hover': {
      borderColor: palette.info[mode],
    },
    '&.Mui-focused': {
      borderColor: palette.info[mode],
    },
    '&:disabled': {
      color: palette.disabled[mode],
    }
  },
  tracerouteInf: {
    color: palette.info[mode],
  },
  tracerouteWar: {
    color: palette.warning[mode],
  },
  tracerouteErr: {
    color: palette.error[mode],
  },
  tracerouteSuc: {
    color: palette.success[mode],
  },
}))

export const TracerouteTest = ({type}) => {
  const [tracerouteApi, setTracerouteApi] = useState("");
  const [tracerouteJitsi, setTracerouteJitsi] = useState("");
  const [tracingApi, setTracingApi] = useState(false)
  const [tracingJitsi, setTracingJitsi] = useState(false)

  const classes = useStyle();

  useEffect(() => {
    setTracerouteApi(null)
    setTracingApi(false)
    setTracerouteJitsi(null)
    setTracingJitsi(false)
  }, [])

  const routeTest = async () => {
    setTracingApi(true)
    setTracingJitsi(true)
    const apiUrl = process.env.REACT_APP_API_URL;
    const jitsiUrl = localStorage.getItem("finalJitsiUrl")
    window.electron.traceroute(apiUrl)
    window.electron.traceroute(jitsiUrl)
    let apiMsg = "", jitsiMsg = "";
    window.electron.onTracerouteRes((event, newRes) => {
      if(newRes.domain === apiUrl) {
        apiMsg += newRes.msg
      } else {
        jitsiMsg += newRes.msg
      }

      if(newRes.code === 101) {
        if(newRes.dst) {
          let jsx = <span className={classes.tracerouteInf}>{newRes.dst} <br/></span>
          if(newRes.domain === apiUrl) {
            setTracerouteApi(jsx)
          } else {
            setTracerouteJitsi(jsx)
          }
        } else if (newRes.hop) {
          if(newRes.hop.rtt1 == "*") {
            let jsx = <span className={classes.tracerouteWar}>
              {newRes.hop.hop} {newRes.hop.ip} {newRes.hop.rtt1}
            </span>
            if(newRes.domain === apiUrl) {
              setTracerouteApi((oldVal) => <>
                {oldVal || ""} 
                {jsx}
                <br/>
              </>)
            } else {
              setTracerouteJitsi((oldVal) => <>
                {oldVal || ""} 
                {jsx}
                <br/>
              </>)
            }
          } else {
            let jsx = <span>{String(newRes.hop.hop).padStart(2, "0")} {newRes.hop.ip} {newRes.hop.rtt1} </span>
            if(newRes.domain === apiUrl) {
              setTracerouteApi((oldVal) => <>
                {oldVal || ""} 
                {jsx}
                <br/>
              </>)
            } else {
              setTracerouteJitsi((oldVal) => <>
                {oldVal || ""} 
                {jsx}
                <br/>
              </>)
            }
          }
        }
      } else {
        let jsx;
        if(newRes.code === 0) {
          jsx = <span className={classes.tracerouteSuc}>{newRes.end}</span>
        } else {
          jsx = <span className={classes.tracerouteErr}>{newRes.end}</span>
        }
        if(newRes.domain === apiUrl) {
          setTracingApi(false)
          setTracerouteApi((oldVal) => <>
            {oldVal || ""} 
            {jsx}
          </>)
          console.alert(132720, "Traceroute API", apiMsg, "End traceroute")
        } else {
          setTracingJitsi(false)
          setTracerouteJitsi((oldVal) => <>
            {oldVal || ""} 
            {jsx}
          </>)
          console.alert(133724, "Traceroute Jitsi", jitsiMsg, "End traceroute")
        }
      }
    })
  }

  return (
    <>
      <div className='row mt-2'>
        <div className='col-md-6'>
          <pre style={{fontSize: "95%", marginBottom: "5px", paddingLeft: "7px", maxHeight: "40vh", scrollY: "auto"}}>{tracerouteApi}</pre>
        </div>
        <div className='col-md-6'>
          <pre style={{fontSize: "95%", marginBottom: "5px", paddingRight: "7px", maxHeight: "40vh", scrollY: "auto"}}>{tracerouteJitsi}</pre>
        </div>
      </div>
      <Button className={classes.modalFormButton} variant='outlined' onClick={routeTest} 
        disabled={tracingApi || tracingJitsi}>
        {
          (tracingApi || tracingJitsi)
          ? "Please wait for all two tests..." 
          : <b>Test network route</b>
        }
      </Button>
    </>
  )
}