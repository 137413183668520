import React ,{useEffect}from "react";
import { ThemeProvider } from '@material-ui/styles';
import theme from '../theme';


export const ThemeToggleContext = React.createContext();

export const MyThemeProvider = ({ children }) => {

  const [mode, setMode] = React.useState('light');

    useEffect(() => {
        let x=localStorage.getItem('mode')
        if(!x)x='light'
        setMode(x)
        if(window.electron)window.electron.toggleDarkMode(x)
    }, [])

  const toggle = () => {
    const x = (mode === 'light' ? `dark` : `light`);
    localStorage.setItem('mode',x)
    if(window.electron)window.electron.toggleDarkMode(x)
    setMode( x);
  };

  return (
    <ThemeToggleContext.Provider value={{ toggleMode: toggle,mode }}>
      <ThemeProvider
        theme={{
            ...theme,
          mode
        }}
      >
        {children}
      </ThemeProvider>
    </ThemeToggleContext.Provider>
  );
};
