


import React, { useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/styles';

const useStyle = makeStyles(({palette, mode}) => ({
  modal: {
    '& .modal-header': {
      backgroundColor:palette.background[mode] + " !important",
      color:palette.color[mode] + " !important"
    },
    '& .modal-header .close': {
      color:palette.color[mode] + " !important"
    },
    '& .modal-content': {
      backgroundColor:palette.background[mode] + " !important",
      color:palette.color[mode] + " !important"
    },
    '& .modal-footer': {
      backgroundColor:palette.background[mode] + " !important",
      color:palette.color[mode] + " !important"
    },
  },
  modalBody: {
    maxHeight: "80vh",
    overflow: "auto"
  },
  modalCloseButton: {
    borderRadius: "50%",
    width: "50px",
    height: "50px",
  }
}))

/**
 *  show: boolean, true to show the modal, false to hide
 *  size: string, 'sm', 'md', 'lg', 'xl' default is 'md'
 *  closeButton: pass boolean or jsx element. true to show the default close button, false to don't show close button
 *  onCloseButton: function to be called when close button is clicked (if close button is set to true)
 *  header: jsx element, will be placed inside div.modal-header
 *  body: jsx element, will be placed inside div.modal-body
 *  footer: jsx element, will be placed inside div.modal-footer
 */
const DraggableModal = ({show=false, size="md", closeButton, onCloseButton, header="", body="", footer=""}) => {
  const [closeJsx, setCloseJsx] = useState("")
  const classes=useStyle()

  useEffect(() => {
    if(closeButton === true) {
      setCloseJsx(
        <button className={"close " + classes.modalCloseButton} 
          title='Close' onClick={onCloseButton}>×</button>
      )
    } else if (closeButton) {
      setCloseJsx(closeButton)
    } else {
      setCloseJsx("")
    }
  }, [closeButton])

  return (
    <Draggable handle='.modal-header'>
      <div role="dialog" aria-modal="true" style={{pointerEvents: "none", display: "block"}} 
        className={"fade modal " + classes.modal + (show ? " show " : " hide ")} tabindex="-1">
        <div className={`modal-dialog modal-${size} modal-dialog-centered`}>
          <div className="modal-content">
            <div className="modal-header" style={{cursor: "move"}}>
              {header}
              {closeJsx}
            </div>
            <div className={"modal-body " + classes.modalBody}>
              {body}
            </div>
            {footer && 
              <div className="modal-footer">
                {footer}
              </div>
            }
          </div>
        </div>
      </div>
    </Draggable>
  )
}
export default DraggableModal