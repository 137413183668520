import {
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import apiService from "../../services/api";
import { toast } from "react-toastify";
import DeleteOutlinedRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import * as colors from '@material-ui/core/colors';

const useStyles = makeStyles({
  root: {
    width: "80%",
    marginRight: "1.5em",
  },
  boardButton: {
    padding: "10px 19px",
    borderRadius: "40px",
    outline: "0px",
    marginLeft: "5px",
  }
});

const RedButton = withStyles((theme) => ({
  root: {
      color: theme.palette.getContrastText(colors.red[700]),
      backgroundColor: colors.red[500],
      '&:hover': {
          backgroundColor: '#ff0000',
      },
  },
}))(Button);

export default function Boards() {
  const [selectedBoardIndex, setBoardIndex] = useState();
  const [boards, setBoards] = useState([]);
  const [pin, setPin] = useState("");
  const handleBoardChange = (index) => {
    setPin("");
    setBoardIndex(index === selectedBoardIndex ? null : index);
  };
  const tabTitle = "Boards";
  const pinLength = 6;
  const classes = useStyles();

  useEffect(() => {
    loadBoards();
  }, []);

  const loadBoards = async () => {
    let res = await apiService.getLocationBoards();
    res = res.data;
    if (res.code === 0) {
      setBoards(res.data.boards);
      if(res.data.boards.length === 1) {
        setBoardIndex(0)
      }
    }
  };

  const sendToken = async (boardId, token) => {
    let toastMsg;
    try{
      if(token) {
        const disconnect = await window.confirmAsync.show(
          <h6 style={{ fontSize: "1.15rem", marginBottom: "0px" }}>Warning</h6>,
          <span style={{ fontSize: "1.05rem", marginBottom: "0px" }}>
            This board is already linked to a device. Do you want to disconnect
            that?
          </span>,
          [
            { value: 1, color: "primary", text: "Yes", close: 1 },
            { value: 0, color: "default", text: "No", close: 1 },
          ]
        )
        if(!disconnect) {
          return;
        }
      }
      let boardsTmp = [...boards]
      let thisBoard = boardsTmp.find(item => item.id == boardId);
      if(thisBoard) {
        thisBoard.disabled = true
        setBoards(boardsTmp)
      }

      toastMsg = toast.info("Link request has been sent. Please wait...", {autoClose: false});
      let res = await apiService.connectBoardToLocation({ pin, boardId });
      if (res.data.code === 0) {
        toast.success(`Board connected successfully. It may take up to 5 seconds for the board to connect.`)
        handleBoardChange(-1)
        let boardsTmp2 = [...boards]
        let thisBoard2 = boardsTmp2.find(item => item.id == boardId);
        if(thisBoard2) {
          thisBoard2.token = res.data.data.token
          setBoards(boardsTmp2)
        }
      } else {
        console.error(1162, "Error while connecting to board", res.data)
        toast.error(res.data.msg)
      }
    } catch (err) {
      console.error(1167, "Error while connecting to board", err)
      toast.error(err.message)
    } finally {
      toast.dismiss(toastMsg)
      let boardsTmp3 = [...boards]
      let thisBoard3 = boardsTmp3.find(item => item.id == boardId);
      if(thisBoard3) {
        thisBoard3.disabled = false
        setBoards(boardsTmp3)
      }
    }
  };

  const logoutBoard = async (boardId) => {
    try{
      const disconnect = await window.confirmAsync.show(
        <h6 style={{ fontSize: "1.15rem", marginBottom: "0px" }}>Warning</h6>,
        <span style={{ fontSize: "1.05rem", marginBottom: "0px" }}>
          You are about to reset this board. Are you sure?
        </span>,
        [
          { value: 1, color: "primary", text: "Yes", close: 1 },
          { value: 0, color: "default", text: "No", close: 1 },
        ]
      )
      if(!disconnect) {
        return;
      }

      let res = await apiService.boardLogoutByUser({ boardId });
      if (res.data.code === 0) {
        toast.success(`Board reset successfully.`)
      } else {
        console.error(1126, "Error while resetting the board", res.data)
        toast.error(res.data.msg)
      }
    } catch (err) {
      console.error(1129, "Error while resetting the board", err)
      toast.error(err.message)
    } finally {
      let boardsTmp = [...boards]
      let thisBoard = boardsTmp.find(item => item.id == boardId);
      if(thisBoard) {
        thisBoard.token = null
        setBoards(boardsTmp)
      }
    }
  };

  const handlePinChange = (event) => {
    event.preventDefault();
    setPin(event.target.value);
  };

  return (
    <div className={classes.root}>
      <div className="my-2">
        <label>{tabTitle}</label>
        <div className="d-flex flex-column">
          {boards.map((board, index) => (
            <>
              <ListItem
                onClick={(event) => handleBoardChange(index)}
                button
                key={index}
                selected={index === selectedBoardIndex}
                style={{
                  borderTopLeftRadius: "10px", 
                  borderTopRightRadius: "10px",
                  borderBottomLeftRadius: index === selectedBoardIndex ? "" : "10px",
                  borderBottomRightRadius: index === selectedBoardIndex ? "" : "10px",
                }}
                disableRipple
              >
                <ListItemIcon>
                  {index === selectedBoardIndex ? (
                    <KeyboardArrowDownOutlinedIcon />
                  ) : (
                    <KeyboardArrowRightOutlinedIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <span className={classes.color}>
                      {board.name}
                      {board.token && (
                        <span style={{ color: "#357a38" }} className="float-right pr-1">
                          <i>Linked</i>
                        </span>
                      )}
                    </span>
                  }
                />
              </ListItem>
              {index === selectedBoardIndex && (
                <div className="px-3 pb-3 pt-1" style={{
                  backgroundColor: "rgba(0, 0, 0, 0.08)", 
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}>
                  {board.token ?
                    <div className="d-flex flex-row-reverse">
                      <RedButton
                        className={classes.boardButton}
                        variant="contained"
                        color="error"
                        onClick={() => logoutBoard(board.id)}
                        startIcon={<DeleteOutlinedRoundedIcon />}
                      >
                        Unlink (Reset)
                      </RedButton>
                    </div>
                  :
                  <div>
                    <TextField
                      size="small"
                      className="w-100"
                      variant="outlined"
                      type="text"
                      onInput={(e) => {
                        if(!isNaN(e.target.value) && String(e.target.value).length <= pinLength) {
                          handlePinChange(e);
                        }
                        // let value = Math.max(0, parseInt(e.target.value))
                        //   .toString()
                        //   .slice(0, pinLength);
                        // e.target.value = value != "NaN" ? value : "";
                        // handlePinChange(e);
                      }}
                      name="pin"
                      placeholder={`Enter pin (${pinLength} digits)`}
                      value={pin}
                      disabled={board.disabled}
                      style={{backgroundColor: "#fff"}}
                    />
                    <div className="d-flex flex-row-reverse">
                      <div>
                        {" "}
                        <Button
                          className="control_btn mt-2"
                          variant="contained"
                          color="secondary"
                          disabled={board.disabled}
                          onClick={() => sendToken(board.id, board.token)}
                        >
                          Send
                        </Button>
                      </div>
                    </div>
                  </div>
                  }
                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
