import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Button } from '@material-ui/core';
import Emoji from '../../constants/emoji';
import VeryBadIcon from '../../assets/images/emoji_hateIcon.png'
import BadIcon from '../../assets/images/emoji_dislikeIcon.png'
import AverageIcon from '../../assets/images/emoji_normalIcon.png'
import GoodIcon from '../../assets/images/emoji_likeIcon.png'
import VeryGoodIcon from '../../assets/images/emoji_LoveIcon.png'
import './modal-style.css';

export const IdleEmojiTimeOutModal = ({ showModal, handleClose, handleTimeout, remainingTime }) => {
    useEffect(() => {
        if (showModal) {
            const timer = setTimeout(() => handleClose(Emoji.NONE), 10000);
            return () => clearTimeout(timer);
        }
    }, [showModal])

    return (
        <Modal show={showModal}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            id="emoji_modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>{window.i18n.getString("pleaseRateYourExperience")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Button onClick={() => handleClose(Emoji.VERY_BAD)}>
                    <img className='emoji_icon' src={VeryBadIcon} alt='emoji_image' />
                </Button>
                <Button onClick={() => handleClose(Emoji.BAD)}>
                    <img className='emoji_icon' src={BadIcon} alt='emoji_image' />
                </Button>
                <Button onClick={() => handleClose(Emoji.AVERAGE)}>
                    <img className='emoji_icon' src={AverageIcon} alt='emoji_image' />
                </Button>
                <Button onClick={() => handleClose(Emoji.GOOD)}>
                    <img className='emoji_icon' src={GoodIcon} alt='emoji_image' />
                </Button>
                <Button onClick={() => handleClose(Emoji.VERY_GOOD)}>
                    <img className='emoji_icon' src={VeryGoodIcon} alt='emoji_image' />
                </Button>
            </Modal.Body>
        </Modal>
    )
}