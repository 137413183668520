import React, { useEffect, useState, useRef } from "react";
import apiService from '../../services/api';
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import tokenService from "../../services/tokenService";
import { IconButton, Tooltip, Grid } from "@mui/material";
import  Download  from '@material-ui/icons/GetApp';
import axios from "axios";
import { jsPDF } from "jspdf";
import PreviewIcon from '@mui/icons-material/Preview';
import ReactLoading from 'react-loading';

const useStyles = makeStyles(({palette,mode}) => ({
  item: {
    width: '24%',
    height:"200px",
    borderRadius:"5px",
    display:"inline-grid",
    position: "relative",
    margin:"0.5%",
  },
  actions:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"end",
  },
  downloadIcon:{
    fontSize: "27px",
    padding: "7px 8px 7px 8px",
    display:"none",
    color:"blue",
    fontWeight:"bolder"
  },
  object: {
    border: "none",
    borderWidth: "0px",
    borderStyle: "none",
    width: "100%",
    height: "100%"
  }
}));

const API_URL = process.env.REACT_APP_API_URL;
let token;

export default function SharedFiles ({handleOnclickImg}){
    const [sharedFiles, setSharedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState([]);
    const classes=useStyles()
    
    useEffect(() => {
      readSharedFilesList()
      token = tokenService.get();
    }, [])

    const readSharedFilesList = async () => {
      try{
        setIsLoading(true)
        const apiCheckRes = await apiService.checkSharedFiles();
        const apiCheckData = apiCheckRes.data
        if(apiCheckData.code !== 0) {
          toast.error(apiCheckData.msg, {autoClose: 10 * 1000})
        }
        if(apiCheckData.data.newArr) {
          let sharedFilesTmp = []
          for(let newFile of apiCheckData.data.newArr) {
            let sFile = {...newFile, src: "", type: "pdf", batchID: "Company Shared Files"}
            const url = API_URL + `desktop/shared-files/${newFile.id}?token=${token}`
            const response = await axios.get(url, { responseType: 'arraybuffer' })
            sFile.fullFile = response.data;
            const blob = new Blob([response.data], {type: "application/pdf"})
            const reader = new FileReader();
            reader.onloadend = () => {
              // dont user blob as dataUrl. doesn't work on firefox on production for some reason?!
              sFile.src = reader.result + '#toolbar=0&navpanes=0&scrollbar=0'
              sharedFilesTmp.push(sFile)
              setSharedFiles([...sharedFilesTmp])
            };
            reader.readAsDataURL(blob);
          }
        }
      } catch (err) {
        console.error(err)
        toast.error(err.message, {autoClose: 10000})
      } finally {
        setIsLoading(false)
      }
    }

    const downloadSharedFile = async (sFile) => {
      var link = document.createElement("a");
      link.href = sFile.src;
      link.download = sFile.name;
      link.click()
    }

    return (
      <>
      {isLoading && 
        <div style={{
          position: "absolute",
          width: "50px",
          height: "50px",
          top: "50%",
          left: "50%",
          margin: "-25px 0 0 -25px",
        }}>
          <ReactLoading type={"spin"} color={"#0085d2"} />
        </div>
      }
      {sharedFiles[0] && sharedFiles.map((sFile, index) => 
        <Grid key={index} className={classes.item}>
          <embed className={classes.object} src={sFile.src + "#view=FitH&toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0&scrollbar=0"} />
          <div className={classes.actions}> 
            <Tooltip title='Download this file'>
              <IconButton className={classes.downloadIcon} onClick={(ev)=>{ev.stopPropagation(); downloadSharedFile(sFile)}} >
                <Download />
              </IconButton>
            </Tooltip>
            <Tooltip title='Preview this file'>
              <IconButton className={classes.downloadIcon} onClick={(ev)=>{ev.stopPropagation(); handleOnclickImg(sFile)}} >
                <PreviewIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      )}
      </>
    );
}

