import React, { Component } from 'react';
import './style.css';
import Constant from '../../constants/constant';
import Str from '../../constants/string';
import UserColor from '../../constants/usercolor';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import MessageIcon from '@material-ui/icons/Message';
import { ThemeToggleContext } from '../../context/theme';
import ButtonBase from "@material-ui/core/ButtonBase";
import { useEffect } from 'react';
import { useState } from 'react';
import {useSelector, useDispatch } from 'react-redux';
import Grid from "@material-ui/core/Grid";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    numberUnRead: {
        display: 'flex',
        position: 'absolute',
        right: '-5px',
        top: '-5px',
        borderRadius:'50%',
        color:"white",
        backgroundColor:"red",
        padding:"0px 3px"
    },
    grid:{
        display: 'flex',
        position: 'relative',
        marginRight:"5px"
    },
}))


export default ({user_name,user_status,location_name,handleOpenChat,display_Name})=>{
    const classes = useStyles();
    const [status,setStatus]=useState()
    const [color,setColor]=useState()
    const unRead = useSelector((state) => state.messages.unRead[user_name])

    useEffect(()=>{
        var userStatusName, statusColor;
        switch (user_status) {
            case Constant.LOGOUT:
                userStatusName = Str.STR_OFF_LINE;
                statusColor = UserColor.BROWN_COLOR;
                break;
            case Constant.AWAY:
                userStatusName = Str.STR_AWAY;
                statusColor = UserColor.YELLOW_COLOR;
                break;
            case Constant.JOIN:
                userStatusName = Str.STR_ON_LINE;
                statusColor = UserColor.GREEN_COLOR;
                break;
            case Constant.CALL:
                userStatusName = Str.STR_ON_CALL;
                statusColor = UserColor.PINK_COLOR;
                break;
            default:
                userStatusName = Str.STR_OFF_LINE;
                statusColor = UserColor.BROWN_COLOR;
        }
        setStatus(userStatusName)
        setColor(statusColor)
    },[user_status])

    const handleOnClickChat=()=> {
       handleOpenChat(user_name,display_Name)
    }

    return (
            <div className='remote_reception_area'>
            <FiberManualRecordRoundedIcon style={{color}} fontSize='small'/>
            <label className='d-flex align-items-center reception_name text-truncate'>
                {display_Name || user_name} 
                <span style={{fontSize: "14px"}}>&nbsp;
                {location_name ? 
                    <span>(<PhoneInTalkIcon fontSize='inherit'/>&nbsp;{location_name})</span>: 
                    ""
                }
                </span>
            </label>
            <ThemeToggleContext.Consumer>{({mode})=>{
                return (
                    <Grid item  className={classes.grid}>
                        <Tooltip title={display_Name || user_name}>
                        <ButtonBase className='btn-chat' onClick={handleOnClickChat}>
                            <MessageIcon style={{color:(mode=='light'?'rgba(0, 0, 0, 0.87)':'#fff')}} />
                        </ButtonBase>
                        </Tooltip>
                        <div className={classes.numberUnRead}>
                            <small>{(unRead && unRead>0)?unRead :''}</small>
                        </div>
                    </Grid>
   
                )
            }}
            </ThemeToggleContext.Consumer>
        </div>    
    );
}